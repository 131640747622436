import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import { Link as RouterLink } from 'react-router-dom';
import shareFill from '@iconify/icons-eva/share-fill';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
 
import palette from '../../theme/palette';
// material
import { styled } from '@mui/material/styles';
 
import { Box, Link, Card, Grid, Avatar, Typography, CardContent, Stack } from '@mui/material';
// utils 
 
//
import SvgIconStyle from '../SvgIconStyle';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});



const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  left:'calc(100%  -30px)',
  width: 100,
  height: 100,
  position: 'absolute',
  bottom: theme.spacing(-8)
}));

 
 
// ----------------------------------------------------------------------

Footer_iead.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default function Footer_iead({ post, index }) {
 
  const latestPostLarge = index === 0;
  const latestPost = index === 1 || index === 2;

 

  return (
    <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle
          sx={{
            ...((latestPostLarge || latestPost) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => theme.palette.grey[900] 
              }
            }),
            ...(latestPostLarge && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
            <Stack direction={{ xs: 'column' }}   justifyContent="center" alignItems="center"  >  
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 190,
            left:'calc(100%  -30px)',
              height: 100,
              zIndex: 9,
              bottom: -45,
              position: 'absolute',
              ...((latestPostLarge || latestPost) && { display: 'none' })
            }}
          />
          <AvatarStyle
    
            src="/static/img/logo.png"
            sx={{
              ...((latestPostLarge || latestPost) && {
                zIndex: 9,
                top: 60,
                left: 24,
                width: 100,
                height: 100
              })
            }}
          />
          
       </Stack>
          <div style={{backgroundColor:palette.azul.main2, minHeight:'30vh'}}><br/><br/><br/>
          
          
          
          </div>
        
        </CardMediaStyle> 
        <br/>     <br/>     <br/>     <br/>  asas   <br/>
      </Card>
    </Grid>
  );
}
