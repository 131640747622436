import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from  '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';
import { signOut } from '../../store/actions/authActions'
import { Label } from '@mui/icons-material';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '#'
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '#'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const [accountUser, setaccountUser] = useState(account());
     const user = useSelector((state) => state.auth);
    const dispatch = useDispatch();
 

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
    function handleSignOut() {
        dispatch(signOut());
    };
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor:  'rgba(121, 236, 252,0.3)'
            }
          })
        }}
      >
        {/*     <Avatar src={account.photoURL} alt="photoURL" />*/}
  
       {(user._id !== null) ? (
                      (user.foto !== null) ?(<Avatar src={user.foto} alt="photoUser" />)
                      :<Avatar src="/static/mock-images/avatars/avatar_default.jpg" alt="photoURL" />)
                   : (<Avatar src={accountUser.photoURL} alt="photoURL" />)}
     
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
                      {(user._id !== null) ? user.name  : accountUser.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

           <label      >{option.label}</label>
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
         {(user._id !== null) ?<Button fullWidth color="inherit" variant="outlined" onClick={handleSignOut} >Sair</Button> : ''}          
        </Box>
      </MenuPopover>
    </>
  );
}
