import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
 

import palette from  '../../theme/palette'


export const AnimatedPage =({children}) => {
return(
    <motion.div 
    animate={{ opacity: 1, x: 0 }}
    initial={{ opacity: 0, x: 100 }}
    exit={{ opacity: 0, x: -20 }}
    transition={{ duration: 1 }}
      >
      {children}
    </motion.div>
)
}

 
  export const AnimatedText =({children}) => {
    const leftVariants = {
      visible: { opacity: 1, scale:[0,1], x:0, transition: { duration: 2 } },
      hidden: { opacity: 0, scale: 0 }
    };
    const controls = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
     if (inView) {
      controls.start("visible");
      }
     }, [controls, inView]);
    return(
        <motion.div 
        ref={ref}
      animate={controls}
      initial="hidden"
      variants={leftVariants}        
          >
          {children}
        </motion.div>
    )
    }
    
   
    export const AnimatedSubtitle = ({ children })  => {
      const leftVariants = {
        visible: { opacity: 1, scale:[0,1], x:0, transition:{delay:3, duration:1},  color: palette.info.darker,   },
        hidden: { opacity: 0, scale: 0 }
      };
      const controls = useAnimation();
      const [ref, inView] = useInView();
      useEffect(() => {
       if (inView) {
        controls.start("visible");
        }
       }, [controls, inView]);
      return(
          <motion.div 
          ref={ref}
        animate={controls}
        initial="hidden"
        variants={leftVariants}        
            >
            {children}
          </motion.div>
      )
      }
    export const AnimatedTextIn =({children}) => {
      const leftVariants = {
        visible: { opacity: 1, scale:[1,0,1], x:0, transition: { duration: 3 } },
        hidden: { opacity: 0, scale: 0 }
      };
      const controls = useAnimation();
      const [ref, inView] = useInView();
      useEffect(() => {
       if (inView) {
        controls.start("visible");
        }
       }, [controls, inView]);
      return(
          <motion.div 
          ref={ref}
        animate={controls}
        initial="hidden"
        variants={leftVariants}        
            >
            {children}
          </motion.div>
      )
      }
     
  export const AnimatedZoom =({children}) => {
    const zoomVariants = {
      visible: { opacity: 1, scale: [1,1.2,0.85], transition: { duration: 1.55 } },
      hidden: { opacity: 0, scale: 0 }
    };
    const controls = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
     if (inView) {
      controls.start("visible");
      }
     }, [controls, inView]);
    return(
        <motion.div 
        ref={ref}
      animate={controls}
      initial="hidden"
      variants={zoomVariants}        
          >
          {children}
        </motion.div>
    )
    }
   
    export const AnimatedZoomG =({children}) => {
      const zoomVariants = {
        visible: { opacity: 1, scale: [1,1.2,1.7,1.5,1.2], transition: { duration: 1.55 } },
        hidden: { opacity: 0, scale: 0 }
      };
      const controls = useAnimation();
      const [ref, inView] = useInView();
      useEffect(() => {
       if (inView) {
        controls.start("visible");
        }
       }, [controls, inView]);
      return(
          <motion.div 
          ref={ref}
        animate={controls}
        initial="hidden"
        variants={zoomVariants}        
            >
            {children}
          </motion.div>
      )
      }
     

 







/*interface Props {
  itemMotion: string; 
  
}

const cardVariants: Variants = {
  offscreen: {
    y: 300
  },
  onscreen: {
    y: 50,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};
 

function Card({ itemMotion }: Props) {
   
  return (
    <motion.div
      className="card-container"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
    >
      <div className="splash"  />
      <motion.div className="card" variants={cardVariants}>
        {itemMotion}
      </motion.div>
    </motion.div>
  );
}

const food: [string][] = [
  [`${<span>1000</span>}`],
  ["🍇"],
  ["🍅"],
  ["🍇"],
  ["🍅"],
  ["🍇"]
];

export default function App() {
  return food.map(([itemM]) => (
    <Card itemMotion={itemM} key={itemM} />
  ));
}
*/