
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/axios";
const initialState = [];


const getAll = () => {
  return api.get("/tabelagenerica_campos/TbGenericaCList");
};
const create = data => {
 return api.post('/TabelaGenerica_campos/novaTabela/', data);
};
const update = (id, data) => {
  return api.put(`/TabelaGenerica_campos/${id}`, data);
};

const remove = id => {
  return api.delete(`/TabelaGenerica_campos/${id}`);
};
const removeAll = () => {
  return api.delete(`/TabelaGenerica_campos`);
};

const findByTitle = title => {
  return api.get(`/TabelaGenerica_campos?title=${title}`);
};

// daqui

export const createCamposGenericos = createAsyncThunk(
  "TabelaGenerica_campos/novaTabela",
  async (formData) => {
    console.log(formData.get('TabelaDesc'));
    const res = await create(formData);
    
    return res.data;
  }
  
);
export const getCamposGenericos = createAsyncThunk(
  "TabelaGenerica_campos/CamposGenericosCList",
  async () => {
    const res = await getAll();
    return res.data;
  }
);

export const updateCamposGenericos = createAsyncThunk(
  "TabelaGenerica_campos/update",
  async ({ id, data }) => {
      // console.log(data.get('ImagemUpload'));
    const res = await update(id, data);
    return res.data;
  }
);

export const deleteCamposGenericos = createAsyncThunk(
  "TabelaGenerica_campos/delete",
  async ({ id }) => {
    const resposta = await remove(id);
    return resposta.data;
  }
);

export const deleteAllCamposGenericos = createAsyncThunk(
  "TabelaGenerica_campos/deleteAll",
  async () => {
    const res = await removeAll();
    return res.data;
  }
);

export const findCamposGenericosByTitle = createAsyncThunk(
  "TabelaGenerica_campos/findByTitle",
  async ({ title }) => {
    const res = await findByTitle(title);
    return res.data;
  }
);

const CamposGenericosSlice = createSlice({
  name: "CamposGenericos",
  initialState,
  extraReducers: {
    
    
 
    [createCamposGenericos.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
   
    [getCamposGenericos.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    
    [updateCamposGenericos.fulfilled]: (state, action) => {
      const index = state.findIndex(CamposGenericos => CamposGenericos.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deleteCamposGenericos.fulfilled]: (state, action) => {
      let index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [deleteAllCamposGenericos.fulfilled]: (state, action) => {
      return [];
    },
    [findCamposGenericosByTitle.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = CamposGenericosSlice;
export default reducer;