import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProduto, updateProduto } from "../../store/reducers/produto/produtosSlice";
import {getProdutos } from "../../store/reducers/produto/produtosSlice";
import { Grid,Card,  Box,Typography, TextField, FormControl, InputLabel, Select, MenIutem, Button, Stack, IconButton, InputAdornment,Avatar, Fab,Input } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import  '../card/styleCard.css';
import { Favorite as FavoriteIcon, KeyboardBackspace as VoltarIcon, ShoppingCartCheckout as cartIcon} from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import api from "../../api/axios";
 
export default function ProdutoAdminForm(props)   {  
  const { currentId, setCurrentId } = props;  
  const [submitted, setSubmitted] = useState('inicio');
  const [images, setImages] = useState(null);
  const [imageNames, setImageNames] = useState(null);
  const [imagesExtra1, setImagesExtra1] = useState(null);
  const [imagesExtra2, setImagesExtra2] = useState(null);
  const [fille, setFille] = useState();
  const [fille2, setFille2] = useState();
  const [fille3, setFille3] = useState();
  const [fille4, setFille4] = useState();
  const ProdutosLista = useSelector((state) => state.produtoStore);  
  //const ProdutoCurrent = ProdutosLista.filter((b) => b.id ==currentId )[0];
  const dispatch = useDispatch();
 
   
  const initFetch = useCallback(() => {
                    dispatch(getProdutos());    
  }, [dispatch])

  useEffect(() => {
                    initFetch();
                    if(currentId>=1){ 
                      setSubmitted(false); 
                      //Passa dados do item selecionado para Edição
                      setValues({...ProdutosLista.find(x => x.id_ == currentId)});                            
                    }
  }, [initFetch, currentId])



   const onChangeImgName = (e) => { 
    setImageNames(URL.createObjectURL(e.target.files[0]));   
    //novo
    e.preventDefault();
    setFille(e.target.files[0]); 
  };
  const onChangeImg = (e) => { 
    setImages(URL.createObjectURL(e.target.files[0]));
    e.preventDefault();
    setFille2(e.target.files[0]); 
  }; 
  const onChangeImgExtra1 = (e) => { 
    setImagesExtra1(URL.createObjectURL(e.target.files[0]));   
    //novo
    e.preventDefault();
    setFille3(e.target.files[0]); 
  };
  const onChangeImgExtra2 = (e) => { 
    setImagesExtra2(URL.createObjectURL(e.target.files[0]));   
    //novo
    e.preventDefault();
    setFille4(e.target.files[0]); 
  };
  
 
  const onSubmit = (values) => {
    
       const formData = new FormData();
        formData.append("Nome", values.nome);  
        formData.append("CodProduto", values.codProduto);
        formData.append("Descricao", values.descricao);
        formData.append("Preco", values.preco);
        formData.append("Quantidade", values.quantidade);
        formData.append("CategoriaId", values.categoriaId);
        formData.append("GeneroId", values.generoId);
        formData.append("SegmentoId", values.segmentoId);
        formData.append("SessaoId", values.sessaoId);   
        formData.append("ImageNome", values.imageNome);
        formData.append("ImagemUpload", fille);
        formData.append("Imagem", values.imagem);
        formData.append("ImagemUpload2", fille2);
        formData.append("CampoExtra_01", values.campoExtra_01);
        formData.append("ImagemUpload3", fille3);
        formData.append("CampoExtra_02", values.campoExtra_02);
        formData.append("ImagemUpload4", fille4);
          console.log(formData.get('ImageNome'));
      
      if (currentId>=1){
         //Função Edite
      dispatch( updateProduto({ id: currentId, data: formData }) )  
      .unwrap()
      .then(data => {         
        resetForm();
        setCurrentId(0);
        dispatch(getProdutos());
        setSubmitted(true);
        toast( 'Editado com Sucesso!', {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#6c63fe', color:'#fff'},  icon:'🚀', });
        console.log(data);
     
      } )
      .catch(e => {
        console.log(e);
      });
      }
      else
      {
        // dispatch(actions.create(formData, onSuccess ));
       //Função Add
      dispatch(createProduto(formData))
       .unwrap()
       .then(data => {       
         resetForm();
         dispatch(getProdutos());
         setSubmitted(true);
         toast( data, {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#6c63fe', color:'#fff'},  icon:'🚀', });
         // console.log(data);
       })
       .catch(e => {
         console.log(e);
         });
        }      
  };
   
  const newProduto = () => { 
    setSubmitted(false);
  };
  const closeProduto = () => { 
    setSubmitted('fechado');
 
  };
 
const CrudSchema = Yup.object().shape({
  nome: Yup.string()
   .min(6, 'Muito Curto! Mínimo 6 caracteres')
   .max(50, 'Muito Longo!')
   .required('O Campo é Obrigatório'),
   codProduto: Yup.string()
  .min(3, 'Muito Curto! Mínimo 6 caracteres')
  .max(20, 'Muito Longo!')
  .required('O Campo é Obrigatório'),
  descricao: Yup.string()
  .min(6, 'Muito Curto! Mínimo 6 caracteres')
  .max(50, 'Muito Longo!')
  .required('O Campo é Obrigatório'),
  preco: Yup.string()
  .typeError("Isso não parece um número")
  .min(2)
  .matches(/^\d+,\d{2}$/,'O campo deve ser no formato de valor')
  .required('O Campo é Obrigatório'),    
});

        
const formik = useFormik({
  initialValues: {
    nome: "",
    codProduto: "",
    descricao: "",    
    preco: "",
    quantidade:"",
    categoriaId:"",
    generoId:"",
    segmentoId:"",
    sessaoId:"",
    imageNome: "",
    imagem:"",
    imagemUpload:"",
    campoExtra_01: "", 
    campoExtra_02:"",
    imageNome1: "",
    imageNome2: "",
    imageNome3: "",
    imageNome4: "",
  },
validationSchema: CrudSchema,    onSubmit,});   
const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps, resetForm, setValues, setErrors,} = formik;
  return (    
    <Stack padding={3}>
      { (submitted=== false) ?(
        <Stack spacing={1}>
           <Box sx={{ flexGrow: 1 }} >
           {/* <Fab style={{fontSize: 15 }} onClick={closeProduto} className="iconButton"  size="small" component="span"  >x</Fab> */}
           <IconButton className="iconButton"  aria-label="add to favorites" onClick={closeProduto}><ClearIcon /></IconButton> 
           <label>Close</label>
           </Box>
 <FormikProvider value={formik}>
   <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
   <Stack spacing={1}>
         <TextField fullWidth label="Titulo" {...getFieldProps('nome')} 
                    error={Boolean(touched.nome && errors.nome)} 
                    helperText={touched.nome && errors.nome}  />
         <TextField fullWidth label="Cod Produto"   {...getFieldProps('codProduto')}
                    error={Boolean(touched.codProduto && errors.codProduto)} 
                    helperText={touched.codProduto && errors.codProduto}  />
         <TextField fullWidth  label="Descricao" {...getFieldProps('descricao')}
                    error={Boolean(touched.descricao && errors.descricao)} 
                    helperText={touched.descricao && errors.descricao}  />
         <TextField fullWidth autoComplete="Preco"label="Preco" {...getFieldProps('preco')}
                    error={Boolean(touched.preco && errors.preco)} 
                    helperText={touched.preco && errors.preco} />
        <TextField fullWidth autoComplete="Quantidade" label="Quantidade" {...getFieldProps('quantidade')}/>
        <TextField fullWidth autoComplete="CategoriaId" label="CategoriaId" {...getFieldProps('categoriaId')}/>
        <TextField fullWidth autoComplete="GeneroId" label="GeneroId" {...getFieldProps('generoId')}/>
        <TextField fullWidth autoComplete="SegmentoId" label="SegmentoId" {...getFieldProps('segmentoId')}/>
        <TextField fullWidth autoComplete="SessaoId" label="SessaoId" {...getFieldProps('sessaoId')}/>  
         <Grid>
         <label> Adicionar Imagem:</label>
         <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>    
          {/*IMG 01  */}
          <Grid>       
             <label> 
               <Input style={{ display: 'none' }}type="file" accept="image/*"  onChange={onChangeImgName}   />
               <Fab style={{fontSize: 30 }} color="secondary" size="small" component="span" aria-label="add" variant="extended">+</Fab>
            </label>          
            {(imageNames) ?(<Avatar sx={{ width: 70, height: 70 }}  src={imageNames} alt=""   />): 
            ((values.imageNome1) ? (<Avatar sx={{ width: 70, height: 70 }} src={values.imageNome1}    alt="" /> ) :(""))}            
          </Grid>
          {/*IMG 02  */}
          <Grid>       
             <label> 
               <Input style={{ display: 'none' }}type="file" accept="image/*"  onChange={onChangeImg}   />
               <Fab style={{fontSize: 30 }} color="secondary" size="small" component="span" aria-label="add" variant="extended">+</Fab>
            </label>          
            {(images) ?(<Avatar sx={{ width: 70, height: 70 }}  src={images} alt=""   />): 
            ((values.imageNome2) ? (<Avatar sx={{ width: 70, height: 70 }} src={values.imageNome2}    alt="" /> ) :(""))}            
          </Grid>
          {/*IMG 03  */}
          <Grid>       
             <label> 
               <Input style={{ display: 'none' }}type="file" accept="image/*"  onChange={onChangeImgExtra1}   />
               <Fab style={{fontSize: 30 }} color="secondary" size="small" component="span" aria-label="add" variant="extended">+</Fab>
            </label>          
            {(imagesExtra1) ?(<Avatar sx={{ width: 70, height: 70 }}  src={imagesExtra1} alt=""   />): 
            ((values.imageNome3) ? (<Avatar sx={{ width: 70, height: 70 }} src={values.imageNome3}    alt="" /> ) :(""))}            
          </Grid>
          {/*IMG 04  */}
          <Grid>       
             <label> 
               <Input style={{ display: 'none' }}type="file" accept="image/*"  onChange={onChangeImgExtra2}   />
               <Fab style={{fontSize: 30 }} color="secondary" size="small" component="span" aria-label="add" variant="extended">+</Fab>
            </label>          
            {(imagesExtra2) ?(<Avatar sx={{ width: 70, height: 70 }}  src={imagesExtra2} alt=""   />): 
            ((values.imageNome4) ? (<Avatar sx={{ width: 70, height: 70 }} src={values.imageNome4}    alt="" /> ) :(""))}            
          </Grid>
          </Stack>
          </Grid>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" > Salvar </LoadingButton>
          <LoadingButton fullWidth size="large" color='warning' variant="contained" loading={isSubmitting}onClick={() => { resetForm() }}>Limpar</LoadingButton>
          </Stack>
      </Form>
    </FormikProvider>
         
    </Stack>
   
      ): (
        <div> 
          {(submitted === 'inicio' || submitted ==='fechado') ?

          ( <Typography variant="h6" letterSpacing={2} >Cadastrar Produto</Typography>):
          ( <Typography variant="h6" letterSpacing={2} > Produto salvo com Sucesso</Typography>)}
          <Button fullWidth size="large" type="submit" variant="contained"   onClick={newProduto} > NOVO </Button>
          
        </div>
      )
      }
    </Stack>
    
  );

};

 