
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState,   useCallback } from "react"; 
import {Box, Card, Grid, Typography, Avatar, CardMedia, autocompleteClasses,FormControl, InputLabel, MenuItem, Select,
  Paper,  Container, Table, TableHead, TableContainer,TableRow, TableCell, TableBody, ButtonGroup, Button, InputAdornment, Toolbar, Alert, Stack } from '@mui/material'; 
import { styled } from "@mui/material"; 
import DeleteOutlineIcon  from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Page from '../components/Page';
import '../components/utilidades/StylePage.css';
import UserAdminForm from '../components/users/userAdminForm';
import {getUsers,  deleteUser} from "../store/reducers/Users/usersSlice";
import { toast } from 'react-toastify';
import {DataGrid /*,  GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarFilterButton, GridToolbarDensitySelector */ } from '@mui/x-data-grid';
import { id } from "date-fns/locale";
 
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ErrorIcon from '@mui/icons-material/Error';
 
const Card_ = styled(Card)({
    minHeight: "85vh",
    width: "90vw",
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  });
 

 
export default function UserAdm() { 
  const [currentId, setCurrentId] = useState(0);    
  const usersLista = useSelector((state) => state.users);
  // const usersLista = useSelector((state) => state.UsersNew.data);
  const dispatch = useDispatch();  
   const [records, setRecords] =[usersLista]; 
  const [loadingg , setLoadingg]=useState(false);
  //console.log(records);  
  
 const initFetch = useCallback(() => {
    dispatch(getUsers());   
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  // useEffect(() => {
  //   dispatch(getUsers());
  // }, [initFetch])

  //  },[loadin]); 
 
   const colunas =[  
  { field: 'id', headerName: 'ID' , width:30},     
                { field: 'nomeCompleto', headerName: 'TITULO' , flex: 1 },
                { field: 'email', headerName: 'EMAIL' ,flex: 1  },
                { field: 'funcao', headerName: 'FUNÇÃO' ,   width:130, renderCell: (cellValues) => {
                    return (
                       <Card style={{margin:'5px 5px 5px 10px', fontSize:'12px', width:'80px', fontWeight:'bold' }} > 
                        {(cellValues.value=="User")?
                     (<Stack backgroundColor="#ebf5f8" height={30}  direction={{ xs: 'column', sm: 'row' }}   justifyContent="center" alignItems="center"> 
                     <ExpandCircleDownIcon fontSize="medium"  color="primary"    /> &nbsp;{ cellValues.value} &nbsp;                                           
                     </Stack>):
                        (cellValues.value=="Admin")?
                     ( 
                     <Stack backgroundColor="#d8fbde" height={30} direction={{ xs: 'column', sm: 'row' }}   justifyContent="center" alignItems="center" color="rgb(10, 85, 84)">  
                        <ExpandCircleDownIcon  color="rgb(54, 179, 126)"    />&nbsp; { cellValues.value}&nbsp;  
                      </Stack>                                          
                      ) :  cellValues.value  
                       }
                   
                        </Card>
                        
                    );
                  }   }, 
                
                { field: 'imagem', headerName: 'FOTO' ,   width:80, renderCell: (cellValues) => {
                  return (  
                    <Avatar src={cellValues.value} alt="img" />
                    // <Card sx={{ width: 120, height: 60}}>
                    //     <CardMedia style={{width:"auto"}} component="img"  height="60"   image={cellValues.value} alt="img"/>

                    //     </Card>
                  );
                }   }  ,
                
                { field: 'id_', headerName: 'AÇÃO' ,   width:120, renderCell: (cellValues) => {
                  return (
                    <ButtonGroup variant="text">
                
                    <Button><EditIcon color="primary"  onClick={() => {setCurrentId(cellValues.value)}} /></Button>
                    <Button><DeleteOutlineIcon sx={{ color: 'palevioletred' }} onClick={() => removeUser(cellValues.value)} /></Button>                     
                    </ButtonGroup>                       
                  );
                }   },            
              ];
            
             // console.log(JSON.stringify(currentId));
const removeUser = id_ => {
      if (window.confirm('Voce tem certeza que quer deletar?'))
          // dispatch(actions.Delete(id, () => addToast("Deletado com Sucesso!", { appearance: 'error'/*'success','warning','info' */})))
          //  console.log(id_);
          dispatch(deleteUser({ id: id_ }))
          .unwrap()
          .then(data => {    
           // toast("Deletado com Sucesso", {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#6c63fe', color:'#fff'},  icon:'🚀', });         
            toast.error("Deletado com Sucesso", {style:{backgroundColor:'#f66b92', color:'#fff'},  icon:'🚀', });
            // console.log(data);
            dispatch(getUsers());            
          })
          .catch(e => {
            console.log(e);
          });
        }
 

 var leg = records.length;
 const maxheigthGrid = (leg>=9)?(350):((leg<=2)?(350):((leg*80)));
  const PaperCard = styled(Card)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.1),
    fontSize:'8px',
    marginLeft:'10px',
    lineHeight:0.1,
    //maxheight: 800, 
    height: maxheigthGrid,
    width: '97%'
     //color: theme.palette.text.secondary,
  // background:"#fdfce1", /* theme.palette.gradients.grey,*/
   
  }));
  
  // const initFetch = useCallback(() => {
  //   dispatch(getUsers());
    
  // }, [dispatch])
 
return(
    <Page title="IEAD | Adm Users">
     
   
   <Grid container item style={{display: 'flex',  justifyContent: 'center'}}  >  
    <Grid item  xs={4}  style={{minWidth:'300px'}}>
      
      <Card style={{padding:'10px 10px 5px 20px'}}   >  <br/>   
       <UserAdminForm  style={{margin:'10px 10px 5px 20px'}} {...({ currentId, setCurrentId })} margin={3}/>
      </Card>
      
    </Grid>
    <Grid item  xs={8}  > 
      <Stack  height={120} direction={{ xs: 'column', sm: 'row' }}    alignItems="center">  
          <img  height={180} width="auto" src="/static/img/girlVectorADM.jpg" alt="girlVectorADM"/>&nbsp;
          <Typography variant="h3_"   color="rgb(10, 85, 84)">Administração de Usuários</Typography>          
      </Stack> <br/>
      {loadingg&&<p>Processando...por favor aguardar...</p>}

      <PaperCard>
         <DataGrid  
          rows={records.map((item, index) => ({
            id:index,    
            id_:item.id_,     
            nomeCompleto: item.nomeCompleto,
            email: item.email,
            funcao: item.funcao,
            imagem: item.imagem
          }))}
         
           columns={colunas} loading={loadingg } hideFooter/>

</PaperCard>
 
       
      
    </Grid>
   </Grid>
      
 {/* {JSON.stringify(records)} */}
   
    
 
</Page>
);
  
 
}