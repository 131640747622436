import {useState,forwardRef, useEffect }  from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
// import Loading from '../../layouts/Loading/Loading'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector }from 'react-redux';
import './styleAlert.css'  
import {numeroAleatorio} from '../utilidades/Funcoes'
import { Card, Typography } from '@mui/material';
import layout from '../../store/layout';
import BodyAlertMUI from './BodyAlertMUI';
const Transition = forwardRef((props, ref) =>
  <div ref={ref}>
    <span>{props.children}</span>
  </div>
);


  

export default function AlertDialogMUI(props) {
  const {dados, textTitle, textButton}= props;
  const user = useSelector((state) => state.auth); 
  const [records, setRecords] = useState(); 
  const [open, setOpen] =useState(records);
  const [conteudo, setConteudo] = useState(); 
   
  const msn = useSelector((state) => state.layoutMessage);
 
  useEffect(() => {
    setConteudo(msn.msn);      
    
   }, [msn.msn]);
 
useEffect(() => {
 // console.log(msn)
  setRecords(msn.showMessage);
   if(records==="true"){
    handleClickOpen();   
   }
 
}, [msn.showMessage]);

  const handleClickOpen = () => {
    setOpen(true);
     
  };

  const handleClose = () => {    
    setOpen(false); 
    
  };
  
  const [textButtom, setTextButtom] =useState(false);
 useEffect(() => {
    fButton(msn.classeParam);
    
}, [msn.classeParam]); 

  const fButton = (e) => {    
    if (e=="foguete"|| e=="successAzul"|| e=="successAzulAnim"||e=="successVerd") {
      setTextButtom(
                  <>
                  <Button href="#text-buttons"  variant="outlined"  style={{  color:'#fff', margin:'5px'}}
                  >ir para home</Button>
                  <Button onClick={handleClose} style={{color:"#000", background:"#fff" }}variant="contained">OK</Button>
                  </>
                );
    }
    if (e=="successAzulClaro"||e=="deleteC"|| e=="deleteAnimation"|| e=="deleteOk"|| e=="errorGif"  || e=="excluir"||e=="confete") {
      setTextButtom(
                  <> 
                  <Button href="#text-buttons"  variant="outlined"  style={{  color:'#000', margin:'5px'}}
                  >ir para home</Button>
                  <Button onClick={handleClose} style={{color:"#000", background:"#83c5be" }}variant="contained">OK</Button>
                   </>
                );
    }
    if (e=="deleteC"|| e=="erroEco"||e=="question") {
      setTextButtom(
                  <> 
                  <Button href="#text-buttons"  variant="outlined"  style={{  color:'#000', margin:'5px'}}
                  >O K </Button>
                  <Button onClick={handleClose} style={{color:"#000", background:"#ffd60a" }}variant="contained">C A N C E L A R</Button>
                   </>
                );
    }
  };
 
  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',    
    textAlign: 'bottom',
    padding: theme.spacing(5, 0),
    color: '#000',// theme.palette.blue.contrastText,
    backgroundColor: 'transparent',
    bottom:"10px"
  }));
  return (
    <div >
      <div  onClick={handleClickOpen}>
      {textButton}
      </div>
      <Dialog  
       PaperProps={{className: msn.classeParam }}
        open={open}          
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      > 
        
        <DialogTitle>{textTitle}</DialogTitle>       
        <DialogContent >
          <DialogContentText  id="alert-dialog-slide-description">
           <RootStyle> 
           <BodyAlertMUI propsBody={conteudo}  propsBodyTema={msn.temaAlert}/>
           </RootStyle>
          </DialogContentText>
        </DialogContent> 
     
         
        <DialogActions> {textButtom} </DialogActions>
      </Dialog>
    </div>
  );
}
