import React, { useEffect, useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import { Card, Box,  Grid,  Typography, Stack  } from '@mui/material';
import Page from '../components/Page';
 
 
import './page.css'
 
import Scroll from "../components/utilidades/ScrollToTop/Scroll"; 
import '../components/slider/fontArt.css'
export default function Homepage() {
 
  // ----------------------------------------------------------------------
  const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
     
    }
}));

  

  return (
    <RootStyle   title="Home | Portal Trilha do Código">
    
      
         <Grid  container height={'90vh'} >
          
          <Grid item container justifyContent={'center'} >
            <img style={{marginBottom:'10px', height:'300px', width:'auto'}} src={'/static/logos/logo1.png'} alt=""/>
          </Grid>
          <Grid  container  >
          <Box sx={{ flexGrow: 2 }}>
             <Stack direction={{  sm: 'column' }}   justifyContent="center" alignItems="center"   > 
             <Typography variant="h1_">Welcome!...</Typography>   
             <Typography  textAlign={'center'} variant="h6" margin={'50px'}>Em breve, novidades no Portal Trilha do Código!</Typography>        
            </Stack> 
            </Box> 
           
          </Grid> 
          
          </Grid>
          
          <Grid item container justifyContent={'center'} >
          <Card style={{marginTop:'-10vh'}}>
          <img style={{marginTop:'-2vh', height:'50vw', width:'auto'}} src={'/static/img/SiteEmConstrucao.png'} alt=""/>
          </Card>
          </Grid>
         
          
        
  
    <Scroll showBelow={250} />
    
 
    </RootStyle>
  );
}
