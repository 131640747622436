const INITIAL_STATE={
    showMessage:"",
    msn:"",
    open:"",
    temaAlert:"",
    dataresult:[]
};

const messageReducer =  (state = INITIAL_STATE, action)=>{
    switch(action.type){
        case 'SHOW_MESSAGE':
            console.log(JSON.stringify(action.msn));
            return {...state, 
                        msn:JSON.stringify(action.msn) ,
                        open: action.open ,
                        showMessage:"true",
                        temaAlert:action.temaAlert,
                        classeParam:action.classeParam,
                        dataresult:action.dataresult};
        case  'HIDE_MESSAGE':
            return { ...state, 
                msn:JSON.stringify(action.msn) ,

                showMessage:false,
                 };
        default:
            return state;
    }
};
 

 

 
export default messageReducer;