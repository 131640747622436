// ----------------------------------------------------------------------
 
 const getToken = localStorage.getItem('token');
  const getUser = localStorage.getItem('user');
 
 
//function account() {
//    return {
//        displayName: (getToken ? getUser : "Usuario Nao Logado"),
//        email: (getToken ? getUser : ""),
//        photoURL: (getToken ? '/static/mock-images/avatars/avatar_default.jpg' : '')
//    }
//};
function account() {
    return {
        displayName: 'Usuario Nao Logado',
        email: (getToken ? getUser : ""),
        photoURL: '/static/mock-images/avatars/User.jpg',
    }
};
export default account;
