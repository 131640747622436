import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import NavbarHomepage from './NavbarHomepage';
import Footer from '../components/footer/Footer';
import '../components/utilidades/beckAnimation.css'
import CartWidget from '../components/Product/ProductCartWidget';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function ThemaOnlyHeader() {
  const [open, setOpen] = useState(false);

  return (
    <>
      
    <RootStyle>
      <NavbarHomepage onOpenSidebar={() => setOpen(true)} />
      <Link to="/cart"><CartWidget/></Link> 
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
 
          
    </>
  );
}


 