import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState,   useCallback } from "react"; 
import  {  Carousel  }  from  'react-responsive-carousel' ;
import  "react-responsive-carousel/lib/styles/carousel.min.css";
import palette from '../../theme/palette';
import {   Tooltip, Badge, MenuItem, ListItemIcon, Pagination, TableCell, TableRow, Card,Box, TablePagination, Grid,  Divider, Container, 
    Typography, CardHeader, CardContent, Avatar, List, ListItem, ListItemText, IconButton, Paper  } from '@mui/material';
    import { styled } from "@mui/material";
    import {AnimatedPage, AnimatedText, AnimatedTextIn, AnimatedZoom, AnimatedZoomG,
        AnimatedSubtitle} from '../utilidades/FramerMotionPage';
        import animatedDataAnalytics from "../../assets/102504-data-analytics.json";
import Page from '../Page';
import { getSliders } from "../../store/reducers/Sliders/slidersSlice";
import { width } from "@mui/system";
export default function Carrossel(props) {
   const { currentWidth,autocompleteValue } = props;
    const slidersLista = useSelector((state) => state.sliderStore);
    const [records, setRecords] = [slidersLista?slidersLista:""]; 
    const dispatch = useDispatch(); 
    useEffect(() => {
        dispatch(getSliders());
      }, []);

 const PaperCard = styled(Card)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.1), 
    lineHeight:0.1,
    //maxheight: 800, 
    height: '120%',
    width: '100%' 
   
  }));
      
       const corText = palette.primary.main;
       const font = (currentWidth==100)?15 :35;
       const font2 = (currentWidth==100)?10 :20;
       const font3 = (currentWidth==100)?12 :20;
      //  console.log(font);
       
       const Img=(src)=>{       
        return <AnimatedZoomG> <img src={src} alt='imagem' 
        style={{ height: '80vh',width:'auto', right: '5%', 
        marginTop: '130px', 
        // boxShadow: 'inset 0 0 -2em #5594c1, 0 0 1em #6c63fe, 0 0 2em #fef5ec, 0 0 2.5em #5594c1'
        boxShadow:'0 0 20px #fefcc9, 10px -10px 30px #feec85'
        }}/> 
        </AnimatedZoomG>  ; 
        }
        console.log(autocompleteValue)
console.log(records.filter(f => f.campoExtra_03 === autocompleteValue))
 return (
    <>
    
           {/* {JSON.stringify(records)} */}
    <PaperCard style={{marginTop: '-120px'}} >
     
    <Carousel infiniteLoop={true} autoPlay showThumbs={false}  interval={5000} >
   
       {records.filter(f => f.campoExtra_03 === autocompleteValue).map((option, index) => (          
         <div key={index}  style={{height:  `100%`, width: '100%', background:(option.campoExtra_01 !==null)?option.campoExtra_01 :'#6c63fe' /*functionCor(option.value)*/}}>   
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6}> 
            <Box sx={{  alignItems: 'center', py: 1, px: 2.5 }}>
            <div className="bannerInf">
            <Typography variant="h2_" color={palette.primary.main} ><br/> </Typography>
            
            <AnimatedZoom> <h3  style={{color:{corText}}}> <span style={{fontSize:font+3}}>{option.titulo}</span> </h3></AnimatedZoom>
                  <div id="aa" style={{fontSize:font2}}>{option.subTitulo}</div>
                <div className="layoutsMais"><a href="#" ><span style={{color:'#F44336', fontSize:font3}}>{option.subDescricao}</span></a></div>
            </div>
            </Box>
 
            </Grid>  
            
            <Grid container item xs={12} sm={12} md={6}>
            <Grid item xs={12} sm={12} md={12} >
              <Box sx={{  alignItems: 'center', py: 1, px: 2.5 }}>
              {(option.imageNome)? Img(option.imageNome):""} 
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{  height: "10vh"}} ></Grid>
                     
            <Grid item xs={12} sm={12} md={6} style={{  height: "20vh"}} ></Grid>
            <Grid item xs={12} sm={12} md={6} style={{  height: "20vh"}} >
            <img src={(option.campoExtra_01 !==null && option.campoExtra_01 =='#fef5ec')?  '/static/img/10.JPG' :  '/static/img/12.JPG'} alt={option.subTitulo}  className="backImgInferior"/>  
      
       
            </Grid>
           </Grid>  
           </Grid>    
           {/* style={{backgroundImage: `url(${option.image})`,
    height:"100%", width:"100%",  }} */}
           {/* backgroundSize:"cover",
      bottom: "0px", overflow:"auto", flex:"1",
      backgroundRepeat: "no-repeat",  */}
          </div>          
          ))}
          
     </Carousel> 
    
     </PaperCard>
     </>
  );
}