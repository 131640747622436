import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
   
import { styled } from '@mui/system';  
import { Grid, TextField, FormControl, InputLabel, Select, MenIutem, Button, Stack, IconButton, InputAdornment,Avatar, Fab,Input } from '@mui/material';
 
import { useSelector, useDispatch, connect} from "react-redux"; 
import {getUsers,updateUser, deleteUser, findUsersById,createUser} from "../../store/reducers/Users/usersSlice"; 
import { LoadingButton } from '@mui/lab'; 
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; 
import AlertDialogMUI from '../sweetAlerts/AlertDialogMUI';

const styles = theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            //minWidth: 230,
        }
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 220,
    },
    smMargin: {
        margin: theme.spacing(2)
    }
})



 
export default function UserAdminForm (props) {
  const { currentId, setCurrentId } = props;  
  const [submitted, setSubmitted] = useState('inicio');
  const userList = useSelector((state) => state.users);
  const [records, setRecords] = [userList];  
    const user = useSelector((state) => state.auth);
  //console.log(userList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);  
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);  
  const [images, setImages] = useState(null);
  const [fille, setFille] = useState();
  const [values_, setValues_] = useState([""]);
 // const [currentId, setCurrentId] = useState(0);
 
 
 const onChangeImg = (e) => { 
  setImages(URL.createObjectURL(e.target.files[0]));   
  //novo
  e.preventDefault();
  setFille(e.target.files[0]);
};
 
 
 
    const CrudSchema = Yup.object().shape({
        nomeCompleto: Yup.string()
         .min(6, 'Muito Curto! Mínimo 6 caracteres')
         .max(50, 'Muito Longo!')
         .required('O Campo é Obrigatório'),
        cpf: Yup.string()
        .typeError("Isso não parece um número de Cpf")
        .min(11)
        .matches(/^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/,'O campo deve ser no formato CPF[000.000.000-00]')
        .required('O Campo é Obrigatório'),
        funcao: Yup.string()
         .min(4, 'Muito Curto! Mínimo 4 caracteres')
         .max(15, 'Muito Longo!')
         .required('O Campo é Obrigatório'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),

      
         password: Yup.string().required('Password é Obrigatório').min(6, 'Password muito curto - deve ter no mínimo 6 caracteres.')
          .matches((currentId == 0)?/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!=+@#\$%\^&\*])(?=.{6,})/, 
          'A Senha conter no mínimo uma letra maiúscula, uma minúscula, um número e um caractere especial'),
    
       
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'As Senhas devem corresponderem!')
      });

       console.log('current Id ==>'+JSON.stringify(currentId))
      const onSubmit = (values) => {
        const Email = values.email;
           //const Password = values.password;
           
        const Imag  = fille;//.get('imageUpload');
        const formData = new FormData();
        formData.append("NomeCompleto", values.nomeCompleto);
        formData.append("Email",Email);
        formData.append("UserName", Email);
        formData.append("Cpf", values.cpf);
        formData.append("Endereco", values.endereco);
        formData.append("Tel", values.tel);
        formData.append("Password", values.password);
        formData.append("ConfirmPassword", values.confirmPassword);
        formData.append("Sexo", values.sexo);
        formData.append("imagemUpload", Imag);
        formData.append("Funcao", values.funcao);
        formData.append("Imagem", values_.imagem);
       // formData.append('fileName', "Img")
        
     //alert(formData.get("NomeCompleto"));
 
     if (currentId != null&&currentId !=""&&currentId!=0){
      //Função Edite
   dispatch( updateUser({ id: currentId, data: formData }) )  
   .unwrap()
   .then(data => {         
    // =====Show Message ==================================================================================================================
                     //"foguete","successAzul","successAzulAnim","successVerd","successAzulClaro","deleteC","deleteAnimation"
                     //"deleteOk","errorGif","excluir","confete","deleteC","erroEco","question"
                     //"bodySuccess", "bodySuccess2", "bodySuccess3","bodySuccess4","bodyconfete","bodyQuestion","bodyDelete","bodyDeleteOk","bodyErroFatal"

                     dispatch({
                      type: "SHOW_MESSAGE",   
                      msn:JSON.stringify(data), 
                      open:true,
                      classeParam:"confete" ,  
                      temaAlert:"bodyconfete"                   
                     });
                     setTimeout(()=>{ dispatch({type: "HIDE_MESSAGE"}); },500)
    // =====Show Message ==================================================================================================================  
     resetForm();
     setCurrentId(0);
     dispatch(getUsers());
     setSubmitted(true);
     toast( 'Editado com Sucesso!', {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#6c63fe', color:'#fff'},  icon:'🚀', });
     console.log(data);
  
   } )
   .catch(e => {
     console.log(e);
   });
   }
   else
   {
     // dispatch(actions.create(formData, onSuccess ));
    //Função Add
   dispatch(createUser(formData))
    .unwrap()
    .then(data => {       
      resetForm();
      dispatch(getUsers());
      setSubmitted(true);
        // =====Show Message ==================================================================================================================
                     //"foguete","successAzul","successAzulAnim","successVerd","successAzulClaro","deleteC","deleteAnimation"
                     //"deleteOk","errorGif","excluir","confete","deleteC","erroEco","question"
                     //"bodySuccess", "bodySuccess2", "bodySuccess3","bodySuccess4","bodyconfete","bodyQuestion","bodyDelete","bodyDeleteOk","bodyErroFatal"

                     dispatch({
                      type: "SHOW_MESSAGE",   
                      msn:JSON.stringify(data), 
                      open:true,
                      classeParam:"confete" ,  
                      temaAlert:"bodyconfete"                   
                     });
                     setTimeout(()=>{ dispatch({type: "HIDE_MESSAGE"}); },500)
       // =====Show Message ==================================================================================================================              
      toast.success( "Adicionado com Sucesso!", {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#2dc653', color:'#fff'},  icon:'🚀', });
      // console.log(data);
    })
    .catch(e => {
      console.log(e);
      });
     }      
    //addOrEdit(formData);
            
  

   

        };


      //  if (user._id ==null) { navigate('/') };
      const formik = useFormik({
        initialValues: {
          nomeCompleto: '',   
          email: '',
          username:'',
          cpf: '',
          endereco: '',
          tel: '',
          password: '',
          confirmPassword: '',
          sexo: '',
          imagem: '',
          funcao:'',
       
        },
        validationSchema: CrudSchema,
          onSubmit,
      });   
      
      const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps, resetForm, setValues, setErrors,} = formik;
    
      useEffect(() => {
        if (currentId != 0) {
          setValues({
            ...userList.find(x => x.id == currentId)
           });
          setValues_({
             ...userList.find(x => x.id == currentId)
            });
            
            setErrors({});
          
        }
    }, [currentId])
  
 

return (
  <FormikProvider value={formik}>
     <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
     <AlertDialogMUI />
       <Stack spacing={1}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <TextField
                fullWidth
                autoComplete="NomeCompleto" 
                label="Nome Completo"
                {...getFieldProps('nomeCompleto')}
                error={Boolean(touched.nomeCompleto && errors.nomeCompleto)}
                helperText={touched.nomeCompleto && errors.nomeCompleto}
              />
              <TextField
                fullWidth
                autoComplete="Cpf" 
                label="Nº do CPF"
                {...getFieldProps('cpf')}
                placeholder="999.999.999-99" 
                error={Boolean(touched.cpf && errors.cpf)}
                helperText={touched.cpf && errors.cpf}
              />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <TextField
                fullWidth
                autoComplete="Email"
                type="email"
                label="Endereco de Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            <TextField
                fullWidth 
                autoComplete="Funcao" 
                label="Funcao"
                {...getFieldProps('funcao')}
                error={Boolean(touched.funcao && errors.funcao)}
                helperText={touched.funcao && errors.funcao}
              />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <TextField
                fullWidth
                autoComplete="Sexo" 
                label="Sexo"
                {...getFieldProps('sexo')}
              />
              <TextField
                fullWidth
                autoComplete="Tel" 
                label="Telefone"
                
                {...getFieldProps('tel')}
              />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
          <TextField
                fullWidth
                autoComplete="Endereco" 
                label="Endereço"
                {...getFieldProps('endereco')}
              />
          </Stack>
 
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <TextField
                fullWidth
                autoComplete="password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                fullWidth
                autoComplete="current-confirmPassword"
                          type={showConfirmPassword ? 'text':'password'}
                          label="Confirmar Password"
                         {...getFieldProps('confirmPassword')}
                InputProps={{
                  endAdornment:(                    
                     <InputAdornment position="end">
                     <IconButton edge="end" onClick={() => setShowConfirmPassword((prev) => !prev)}>
                       <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                     </IconButton>
                   </InputAdornment>
                  )
                }}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />

          </Stack>
         
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
               <label>
                 Adicionar Foto:
                </label>
             <label> 
               <Input
                  style={{ display: 'none' }}
                  type="file" 
                  accept="images/users/*"
                  // label="Foto"
                //  {...getFieldProps('imagem')}
                  onChange={onChangeImg} 
                />
                   <Fab
                   style={{fontSize: 30 }}
                    color="secondary"
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                     +
                     {/* <Icon icon="mdi:plus-circle" style={{ color: 'red' }} /> */}
                       
                    {/* <AddIcon /> Upload photo */}
                  </Fab>
            </label>
          
            {(images) ?(<Avatar sx={{ width: 70, height: 70 }}  src={images} alt="" />)
            : ((values.imagem) ? (<Avatar sx={{ width: 70, height: 70 }}  src={values.imagem} alt="" /> ) :(""))
            }
  
             
            
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={() => {  
              setImages()
            }}
          >
            Salvar
          </LoadingButton>
          <LoadingButton
           fullWidth
           size="large"   
           //style={{backgroundColor: '#12824C', color: '#FFFFFF'}}
           color='warning'
           variant="contained"
           loading={isSubmitting}                       
           onClick={() => { 
                            resetForm()
                            setImages()
                          }}
       
           >
            Limpar
          </LoadingButton>
          </Stack>
        </Stack>
        </Form>
    </FormikProvider>
     
    );
}
//export default (withStyles(styles)(DCandidateForm));

// const mapStateToProps = state => ({
//     userList: state.users.list
// })

// const mapActionToProps = {
//     createUser: actions.create,
//     updateUser: actions.update
// }

// export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(UserAdminForm));