import axios from "axios";
 
import { getUser, deleteUser } from '../utils/utils'
const api = axios.create({
//   baseURL: "https://localhost:44375/api/", 
 
//baseURL: "http://ecommerceapi.web21f50.uni5.net/api/",
 baseURL: "http://portaliead.web21f18.uni5.net/api/",
   
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  
  api.interceptors.response.use(
    response => {
        // Do something with response data
  
      return response
    },
    error => {
  
      // Do something with response error
  
      // You can even test for a response code
      // and try a new request before rejecting the promise
  
     
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.setItem("userLog", "NOT");
        localStorage.setItem("userLogMsg", "Erro nas Credencias - Possivel expiração de token");} 
   
  
      return Promise.reject(error)
    },
  );
  
  api.interceptors.request.use(
    config => {   
      return getUser()
        .then(user => {
          user = localStorage.getItem("token")
          if (user)
            config.headers.Authorization = `Bearer ${user}`
          return Promise.resolve(config)
        })
        .catch(error => {
          console.log(error)
          return Promise.resolve(config)
        })
    },
    error => {
      return Promise.reject(error)
    },
  )
  
  export default api
 
