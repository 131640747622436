import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState,   useCallback } from "react"; 
import  {  Carousel  }  from  'react-responsive-carousel' ;
import  "react-responsive-carousel/lib/styles/carousel.min.css";
import palette from '../../theme/palette';
import {   Tooltip, Badge, MenuItem, ListItemIcon, Pagination, TableCell, TableRow, Card,Box, TablePagination, Grid,  Divider, Container, 
    Typography, CardHeader, CardContent, Avatar, List, ListItem, ListItemText, IconButton,Button, Paper  } from '@mui/material';
    import { styled } from "@mui/material";
    import {AnimatedPage, AnimatedText, AnimatedTextIn, AnimatedZoom, AnimatedZoomG,
        AnimatedSubtitle} from '../utilidades/FramerMotionPage';
        import animatedDataAnalytics from "../../assets/102504-data-analytics.json";
import Page from '../Page';
import { getSliders } from "../../store/reducers/Sliders/slidersSlice";
import { width } from "@mui/system";
export default function CarrosseModelo3(props) {
   const { currentWidth } = props;
    const slidersLista = useSelector((state) => state.sliderStore);
    const [records, setRecords] = [slidersLista?slidersLista:""]; 
    const [imgBack , setImgBack]=useState(false);
    const dispatch = useDispatch(); 
    useEffect(() => {
        dispatch(getSliders());
      }, []);

 const PaperCard = styled(Card)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.1), 
    lineHeight:0.1,
    //maxheight: 800, 
    height: '120%',
    width: '100%' 
   
  }));
  const PaperCardTitle = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    margin:  '0 12vw', 
    lineHeight:0.1,
    //maxheight: 800, 
    height: '100%',
    width: '100%' ,
    textAlign:'left',
    background:'transparent'
   
  }));

    const [size, setSize] = useState({x: window.innerWidth, y: window.innerHeight });
    const updateSize = () =>  setSize({x: window.innerWidth,   y: window.innerHeight  });
    useEffect(() => (window.onresize = updateSize), []);
 
    let posicao= (size.x <= 400)?'80%':(size.x <= 600)?'75%':(size.x <=800)?'75% ':(size.x <=1000)?'70% ':(size.x <=1200)?'65% ':'40%';
    let heig = (size.x <= 400)?'60vh':(size.x <= 600)?'50vh':(size.x <=800)?'70vh':'75vh';

 return (
    <>
    
           {/* {JSON.stringify(records)} */}
    <PaperCard style={{marginTop: '-120px'}} >
     
    <Carousel infiniteLoop={true} autoPlay showThumbs={false}  interval={5000} >
   
       {records.filter(f => f.campoExtra_03 === "30").map((option, index) => (          
         <div key={index}  style={{height:heig, background:(option.imageNome)? ('url('+option.imageNome+') no-repeat'):"#F44336",backgroundSize: 'cover',backgroundPosition: posicao}}> 
        
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6}> 
            <PaperCardTitle  >
           
            <Typography><br/><br/><br/><br/> <br/> </Typography>
            <AnimatedZoom  >
            <Typography   variant="h3_" color={palette.primary.main} >  <span  >{option.subDescricao}</span></Typography>           
           <Typography variant="h1_" color={palette.primary.main} ><br/>  <span >{option.titulo}</span></Typography>
           <Typography variant="h2_" color={palette.warning.dark} ><br/>  <span  >{option.subTitulo}</span></Typography><br/>
           <Button   variant="outlined" size="large">Saber Mais</Button>
            </AnimatedZoom>
            
            </PaperCardTitle>
 
            </Grid>  
            
            <Grid container item xs={12} sm={12} md={6}>
            <Grid item xs={12} sm={12} md={12} >
               
            </Grid>
            
           </Grid>  
           </Grid>    
           {/* style={{backgroundImage: `url(${option.image})`,
    height:"100%", width:"100%",  }} */}
           {/* backgroundSize:"cover",
      bottom: "0px", overflow:"auto", flex:"1",
      backgroundRepeat: "no-repeat",  */}
          </div>          
          ))}
          
     </Carousel> 
    
     </PaperCard>
     </>
  );
}