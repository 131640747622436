
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState,   useCallback } from "react"; 
import {Box, Card, Grid, Typography, Avatar, CardMedia, autocompleteClasses,FormControl, InputLabel, MenuItem, Select,
  Paper,  Container, Table, TableHead, TableContainer,TableRow, TableCell, TableBody, ButtonGroup, Button, InputAdornment, Toolbar, Stack } from '@mui/material'; 
import { styled } from "@mui/material";
 
import DeleteOutlineIcon  from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Page from '../components/Page';
import '../components/utilidades/StylePage.css';
import SliderAdminForm from '../components/slider/SliderAdminForm' 
import Carrossel from "../components/slider/Carrossel";
import { getSliders, updateSlider, deleteSlider } from "../store/reducers/Sliders/slidersSlice";
import { toast } from 'react-toastify';
 

// import {getSlidersNew} from "../store/reducers/Sliders/getSlidersSlice";

import {DataGrid /*,  GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarFilterButton, GridToolbarDensitySelector */ } from '@mui/x-data-grid';
import { id } from "date-fns/locale";
import Toast from "../components/utilidades/Toast";
import CarrosseFull from "../components/slider/CarrosseFull";
import CarrosseModelo3 from "../components/slider/CarrosseModelo3";
 
// backBobble

const ContentStyle = styled('div')(({ theme }) => ({
  width: '99%',
  fontSize:'calc(1em * 2)',
  margin: 'auto',
  //  display: 'flex',
  height: "370px",
  // flexDirection: 'column',
  // justifyContent: 'center',
}));
const Card_ = styled(Card)({
 
    // backgroundImage: "-webkit-linear-gradient(0deg, #ffffff 30%, rgba(173, 215, 227,1) 70%)",
    minHeight: "85vh",
    background:'rgba( 240, 230, 220,1)',
    width: "90vw",
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  });
  const RootStyle = styled(Page)(({ theme }) => ({
    minHeight:'100vh',
    display: 'flex',    
    marginTop:'-120px',
    alignItems: 'center',
    padding: '100px 20px 10px 20px',
  
    // backgroundImage: "-webkit-linear-gradient(0deg, rgba(203, 228, 255,1) 50%, rgba(21, 37, 53,1) 50%)",
     
  }));


 
export default function SliderAdm() {
  const [currentWidth, setCurrentWidth]= useState(0);
  const [currentId, setCurrentId] = useState(0); 
  const [autocompleteValue, setAutocompleteValue] = useState();

  // useEffect(() => {
  //   dispatch(getSliders()); 
  
  // }, []);
// const inicalState ={
//   id:"",
//   titulo:"",
//   subTitulo:"",
//   image:"",
//   imageNome:""
// }
  const slidersLista = useSelector((state) => state.sliderStore);
 
  // const slidersLista = useSelector((state) => state.SlidersNew.data);
  const dispatch = useDispatch(); 
  
    // const loadin= useSelector((state) => state.loading); 
  // const records = slidersLista;
  // const records = slidersLista;
   const [records, setRecords] =[slidersLista]; 
  const [loadingg , setLoadingg]=useState(false);
  //  console.log(records);  
  
 const initFetch = useCallback(() => {
    dispatch(getSliders()); 
    setCurrentWidth(100);   
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  // useEffect(() => {
  //   dispatch(getSliders());
  // }, [initFetch])

  //  },[loadin]); 
 
   const colunas =[  
  { field: 'id', headerName: 'ID' , width:30},
  // { field: 'id_', headerName: 'id_' , width:30},
                { field: 'titulo', headerName: 'TITULO' , flex: 1 },
                { field: 'subTitulo', headerName: 'SUBTITULO' ,flex: 1  },
                { field: 'descricao', headerName: 'DESCRIÇÃO' , flex: 1 },
                { field: 'imageNome', headerName: 'IMAGEM' ,   width:120, renderCell: (cellValues) => {
                  return (  
                    <Card sx={{ width: 120, height: 60}}><CardMedia style={{width:"auto"}} component="img"  height="60"   image={cellValues.value} alt="img"/></Card>
                  );
                }   }  ,
                
                { field: 'id_', headerName: 'AÇÃO' ,   width:120, renderCell: (cellValues) => {
                  return (
                    <ButtonGroup variant="text">
                
                    <Button><EditIcon color="primary"  onClick={() => {setCurrentId(cellValues.value)}} /></Button>
                    <Button><DeleteOutlineIcon sx={{ color: 'palevioletred' }} onClick={() => removeSlider(cellValues.value)} /></Button>                     
                    </ButtonGroup>                       
                  );
                }   },            
              ];
             // console.log(JSON.stringify(currentId));
const removeSlider = id_ => {
      if (window.confirm('Voce tem certeza que quer deletar?'))
          // dispatch(actions.Delete(id, () => addToast("Deletado com Sucesso!", { appearance: 'error'/*'success','warning','info' */})))
          //  console.log(id_);
          dispatch(deleteSlider({ id: id_ }))
          .unwrap()
          .then(data => {
             
            toast.error(data, {style:{backgroundColor:'#f66b92', color:'#fff'},  icon:'🚀', })
            // console.log(data);
            dispatch(getSliders());            
          })
          .catch(e => {
            console.log(e);
          });
        }
  //  const removeSlider = () => {
  //   dispatch(deleteSlider({ id: currentSlider.id }))
  //     .unwrap()
  //     .then(() => {
  //       props.history.push("/sliders");
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };


 var leg = records.length;
 const maxheigthGrid = (leg>=9)?(350):((leg<=2)?(350):((leg*80)));
  const PaperCard = styled(Card)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.1),
    fontSize:'8px',
    marginLeft:'10px',
    lineHeight:0.1,
    //maxheight: 800, 
    height: maxheigthGrid,
    width: '97%'
     //color: theme.palette.text.secondary,
  // background:"#fdfce1", /* theme.palette.gradients.grey,*/
   
  }));
  
  // const initFetch = useCallback(() => {
  //   dispatch(getSliders());
    
  // }, [dispatch])
  const handleChange = (event) => {
    setAutocompleteValue(event.target.value);
  }; 
 
 console.log(records)
  
return(
  <>
<RootStyle  >

   <Card_ >
      
   <Grid container item style={{display: 'flex',  justifyContent: 'center'}}  > 
 
    <Grid item  xs={3}  style={{minWidth:'300px'}}>
      <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
      
        
      </Box>
      <Card style={{margin:'5px 5px 2px 5px'}}  >   
      <FormControl fullWidth style={{marginTop:'8px'}} >
                          <InputLabel id="select-label">Tipo Slider</InputLabel>       
                            <Select  
                            labelId="select-label" 
                            label="Tipo Slider"                     
                              id="demo-simple-select"
                              value={autocompleteValue}                      
                              onChange={handleChange}
                            > 
                             <MenuItem value={"10"}>Padrao</MenuItem>
                            <MenuItem value={"20"}>Full Imagem</MenuItem>
                            <MenuItem value={"30"}>Modelo 03</MenuItem>                     
                            </Select>
  </FormControl>   
      <SliderAdminForm {...({ currentId, setCurrentId,autocompleteValue })}/>
      </Card>
      
    </Grid>
    <Grid item  xs={9} style={{ height:'80vh',minWidth:'300px'}} > 

     <Box sx={{ display: 'flex',   justifyContent:'center' }}>
      <Stack  height={260} direction={{ xs: 'column', sm: 'row' }}    alignItems="center">  
          <img  height={250} width="auto" src="/static/img/admSliders.png" alt="admSliders"/>&nbsp;
          <Typography variant="h2"   color="#000">Administração de Sliders</Typography>          
      </Stack> <br/>
      </Box>
      <Box sx={{ display: 'flex',   justifyContent:'right', margin:'20px' }}>
      <Button variant="outlined"onClick={() => {setAutocompleteValue("");dispatch(getSliders());}}>Mostrar Todos</Button>
      </Box>
      <PaperCard  >  
      {loadingg&&<p>Processando...por favor aguardar...</p>}
      
         <DataGrid  
          rows={records.filter(f =>autocompleteValue? f.campoExtra_03 === autocompleteValue:f).map((item, index) => ({
            id:index,    
            id_:item.id_,     
            titulo: item.titulo,
            subTitulo: item.subTitulo,
            descricao: item.descricao,
            imageNome: item.imageNome
          }))}
         
           columns={colunas} loading={loadingg } hideFooter/>

  
 
       </PaperCard>

       {/* <Toast/> */}
      
    </Grid>
   </Grid>
      
 {/* {JSON.stringify(records)} teste para os tipos d e toast */}
   
    </Card_>
    
</RootStyle>
 
 <Box sx={{/* display: 'flex',*/ alignItems: 'center', py: 2, px: 2.5 }}>
          <Typography style={{fontSize:30, fontWeight:300}} letterSpacing={5} >Sliders View: </Typography>          
          <Card style={{ display: 'flex', alignItems: 'center', margin:'2px 10px 2px 20px', height: '800px', width: '100%'}}  >               
          <ContentStyle >
           {(autocompleteValue==='10')?(<Carrossel autocompleteValue={autocompleteValue} {...({currentWidth, setCurrentWidth})} /> ):
              (autocompleteValue==='20')?(<CarrosseFull/> ):
              (autocompleteValue==='30')?(<CarrosseModelo3/> ):""
            }
          
          </ContentStyle>
                        
          </Card>
      </Box>
      </>
);
  
 
}