import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState,   useCallback } from "react"; 
import  {  Carousel  }  from  'react-responsive-carousel' ;
import  "react-responsive-carousel/lib/styles/carousel.min.css";
import palette from '../../theme/palette';
import {    Card, Grid,  Typography, Button  } from '@mui/material';
import { styled } from "@mui/material";
import { AnimatedZoom,   } from '../utilidades/FramerMotionPage';
import { getSliders } from "../../store/reducers/Sliders/slidersSlice";
import  './slidersStyle.css'

 
export default function CarrosseFull(props) {
   const { currentWidth } = props;
    const slidersLista = useSelector((state) => state.sliderStore);
    const [records, setRecords] = [slidersLista?slidersLista:""]; 
    const dispatch = useDispatch(); 
    useEffect(() => {
        dispatch(getSliders());
      }, []);

 const PaperCard = styled(Card)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.1), 
    lineHeight:0.1,
    //maxheight: 800, 
    height: '120%',
    width: '100%' 
   
  }));
  const PaperCardTitle = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    margin:  '0 3vw', 
    lineHeight:0.1,
    //maxheight: 800, 
    height: '100%',
    width: '100%' ,
    textAlign:'left',
    background:'transparent'
   
  }));
  const PaperText = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
    margin:  '1 2vw', 
    lineHeight:0.9,
    //maxheight: 800, 
    height: '100%',
    width: '100%' ,
    textAlign:'left',
    background:'transparent'
    
   
  }));
      
  const [size, setSize] = useState({x: window.innerWidth, y: window.innerHeight });
  const updateSize = () =>  setSize({x: window.innerWidth,   y: window.innerHeight  });
  useEffect(() => (window.onresize = updateSize), []);

  let posicao= (size.x <= 400)?'30%':(size.x <= 600)?'45%':(size.x <=800)?'50% ':(size.x <=1000)?'55% ':(size.x <=1200)?'60% ':'';
  let heig = (size.x <= 400)?'60vh':(size.x <= 600)?'50vh':(size.x <=800)?'70vh':'75vh';
  

  const aa= <Grid container item xs={12} sm={12} md={4}>
  <Grid item xs={12} sm={12} md={12} > </Grid>            
</Grid>;

 return (
    <>
    
           {/* {JSON.stringify(records)} */}
    <PaperCard  style={{marginTop: '-120px'}} >
     
    <Carousel infiniteLoop={true} autoPlay showThumbs={false}  interval={5000}  >
   
       {records.filter(f => f.campoExtra_03 === "20" ||  f.campoExtra_03 === "30").map((option, index) => (     
        
      //   {records.filter(f => f.campoExtra_03 === "20").map((option, index) => (    
        
         <div key={index}  style={{height:  heig,   background:(option.imageNome)? ('url('+option.imageNome+')no-repeat'):"#F44336",backgroundSize: 'cover',backgroundPosition: posicao}}>   
          <Grid container spacing={0}>
            {/* Se For Conteudo à Direita  */}
            {(option.campoExtra_03 === "20")?aa:""} 
             
             
            <Grid item xs={12} sm={12} md={8}>
             
            {/* Se For Conteudo à Esquerda  */}
              
            {(option.descricao )? 
            <PaperCardTitle  >           
            <Typography><br/><br/><br/><br/> <br/> </Typography>            
            <AnimatedZoom >             
                <PaperText className="textBubTitle" color={palette.primary.main} >  <span  >{option.subTitulo }</span></PaperText> <br/>          
                <PaperText   className="textTitle" style={{maxWidth:'650px'}} >  {option.titulo}</PaperText>
                <PaperText className="textSubTitle2"  ><br/>  <span  >{option.descricao}</span></PaperText><br/>
                <PaperText><br/><Button   align="center"  className="textButon" variant="outlined" size="large">Saber Mais</Button>   </PaperText>        
            </AnimatedZoom>            
            </PaperCardTitle>:""
            }

            </Grid>  
            
             
           </Grid>    
           
          </div>    
                
          ))}
          
     </Carousel> 
    
     </PaperCard>
     </>
  );
}