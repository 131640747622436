import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/system';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import loginIllustration from '../img/loginIllustration.jpg';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));
// ----------------------------------------------------------------------

export default function Login() {
    return (
        <RootStyle title="Login | Ecommerce">
            <AuthLayout>
                Ainda não tem uma conta? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/users/register"> Registrar</Link>
            </AuthLayout>

            <MHidden width="mdDown">
                <SectionStyle>
                    <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>Ola, Bem vindo de volta.</Typography>
                    <img src="../static/illustrations/illustration_login.jpg" alt="login" />
                </SectionStyle>
            </MHidden>

            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack sx={{ mb: 5 }}>
                        <Typography variant="h4" gutterBottom>Login </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Entre com suas credenciais.</Typography>
                    </Stack>
                    <LoginForm />
                    <MHidden width="smUp">
                        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                            Ainda não tem uma conta?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                                Registrar
              </Link>
                        </Typography>
                    </MHidden>
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
