import { configureStore } from "@reduxjs/toolkit";
import loremSlice from "./reducers/lorem/loremSlice";
import baseFolhasSlice from "./reducers/folhas/baseFolhasSlice";
import sliderReducer from './reducers/Sliders/slidersSlice';
import baseSlidersSlice from './reducers/Sliders/getSlidersSlice';
import tbGenericaReducer from './reducers/tbGenerica/tbGenericasSlice';
import cGenericosReducer from './reducers/tbGenerica/camposGenericosSlices';

import produtoReducer from './reducers/produto/produtosSlice';

import { setupListeners } from "@reduxjs/toolkit/dist/query";
import CartSlice from "./reducers/Cart/CartSlice";
import authReducer from './reducers/authReducer';
import userReducer from './reducers/Users/usersSlice'
import messageReducer from "./layout";
let initialState = {};
const store = configureStore({
  reducer: {
    lorem: loremSlice.reducer,
    baseFolha: baseFolhasSlice.reducer,
    sliderStore: sliderReducer,
    tbGenericaStore: tbGenericaReducer,
    cGenericosStore:cGenericosReducer,
    SlidersNew: baseSlidersSlice.reducer,
    produtoStore:produtoReducer,
    auth: authReducer,
    users:userReducer,
    layoutMessage:messageReducer,

    //cart template
   // categories: CategorySlice,
  
    carts: CartSlice,
  },
  
},
//cart template
initialState,
 window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
 );

export default store;