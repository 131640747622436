import axios from "axios";
//import { url } from "../../api";
import { toast } from "react-toastify"; 
 //const url = "http://localhost:5001/api/";
 //  const url = "https://localhost:44375/api/";
  const url ="http://portaliead.web21f18.uni5.net/api/";
 


 export const signUp = (formData) => {
    //console.log(formData.get('Email'));   
    return (dispatch) => {
             
        axios
            .post(`${url}auth/nova-conta`, formData )
            .then((token) => {
                localStorage.setItem("token", token.data.token);
                dispatch({
                    type: "SIGN_UP",
                    token: token.data.token,
                    name: token.data.email,
                    email: token.data.email,
                    _id: token.data._id,
                });
                console.log("chegou aqui no authAction>==> " + JSON.stringify(token.data.password));
            })
            .catch((error) => {
                console.log(error.response);

                toast.error(error.response?.data, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            });
    };
};

export const signIn = (formData) => {
   
    return (dispatch) => {
//    console.log("chegou aqui no authAction>==> " + JSON.stringify(formData.get('Password')));
        axios
            .post(`${url}auth/entrar`, formData)
            .then((token) => {
                localStorage.setItem("token", token.data.token);
                localStorage.setItem("user", token.data.name);
                localStorage.setItem("_id", token.data._id);   
                localStorage.setItem("foto", token.data.foto); 
                localStorage.setItem("password", token.data.password);  
                localStorage.setItem("funcao", token.data.funcao);          
                dispatch({
                    type: "SIGN_IN", 
                    token: token.data.token,
                    name: token.data.name,
                    email: token.data.email,
                    _id: token.data._id,
                    password: token.data.password,
                    foto:token.data.foto,
                    funcao:token.data.funcao,
                });
               
    
              // console.log("chegou aqui no authAction>==> " + JSON.stringify(token.data.email));

            })
            .catch((error) => {
           
                if(error.response){

    // =====Show Message ==================================================================================================================
                     //"foguete","successAzul","successAzulAnim","successVerd","successAzulClaro","deleteC","deleteAnimation"
                     //"deleteOk","errorGif","excluir","confete","deleteC","erroEco","question"
                     //"bodySuccess", "bodySuccess2", "bodySuccess3","bodySuccess4","bodyconfete","bodyQuestion","bodyDelete","bodyDeleteOk","bodyErroFatal"

                   dispatch({
                    type: "SHOW_MESSAGE",   
                    msn:JSON.stringify(error), 
                    open:true,
                    classeParam:"erroEco" ,  
                    temaAlert:"bodyErroFatal"                   
                   });
                   setTimeout(()=>{ dispatch({type: "HIDE_MESSAGE"}); },500)
    // =====FIM Show Message ===============================================================================================================             

                    dispatch({
                        type: "SIGN_ERROR",                         
                    });
                   


                //new
                
                // dispatch({
                //     type: "SHOW_MESSAGE", 
                //     open:'true'//.response?.data,
                // });
                // setTimeout(()=>{
                //     dispatch({
                //         type: "HIDE_MESSAGE",                            
                //         msn:error, 
                //         open:false,
                    
                //     });
                // },1000)






                }
                else{
                    dispatch({
                        type: "SIGN_ERROR", 
                        msn:"Erro ao Logar...   Não foi possivel obter o motivo do erro",
                    }); 
                }

                // toast.error(error.response?.data, {
                //     position: toast.POSITION.BOTTOM_RIGHT,
                // });
               
            });
    };
};

export const signOut = () => {
    return (dispatch) => {
        dispatch({
            type: "CLEAR_TODOS",
        });

        dispatch({
            type: "SIGN_OUT",
        });
       
    };
    console.log("ola... sai");
};

export const loadUser = () => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        const name = getState().auth.token.name;
        const email = getState().auth.email;
        const _id = getState().auth._id;
        if (token) {
            dispatch({
                type: "USER_LOADED",
                token,
                name,
                email,
                _id
            });
        } else return null;
    };
};
