import React, { Fragment, useEffect, useState,useContext } from "react"; 
import { Link, useParams } from "react-router-dom"; 
import "./ProductDetails.css";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useDispatch, useSelector } from "react-redux"; 
import { addToCarts } from "../../../store/reducers/Cart/CartSlice";
import { Button, ButtonGroup, Card, CardMedia, Grid, Input, Stack, Typography } from "@mui/material";
import { Label } from "@mui/icons-material";
import { MoneyCurrency } from "../../../utils/formatNumber";
 
 
import styled from "styled-components";
import "../../../components/carrossel/produtosView.css";
import { device } from "../../../components/carrossel/mediaBreakPoints"; 
import Carousel from "../../../components/carrossel/Carrossel";
import { CartContext, ToggleContext } from "../../../components/carrossel/useCartToggle";
import ModalView from "../../../components/carrossel/modal";



const Container = styled.div`
width: 100vw;
max-width: 1600px;
margin: 0 auto;
padding: auto;
/* padding: 1em 2em; */
box-sizing: border-box;

* {
  
  box-sizing: border-box;
  /* font-size: 20px; */
}
& p {
  font-weight: 100;
  font-size: small;
  color: hsl(219, 9%, 45%);
}
@media ${device.mobileS} {
  /* padding: 1em 2em; */
  & .child2 {
    padding: 0.5em 1em;
  }
}
`;

const Flex = styled.div`
margin: auto;
display: flex;
flex-direction: column;
position: relative;
justify-content: center;
column-gap: 3em;
/* align-items: center; */
padding: 0.4em;
/* border: 2px blue solid; */

& .child1,
.child2 {
  width: 100%;
}

& #product-img {
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 1em;
}
 
 
 
 
 
@media ${device.mobileL} {
  flex-direction: column;

  & .child1,
  .child2 {
    width: 100%;
    /* background-color: blue; */
  }
  & .child2 {
    padding: 0 1.5em;
  }
}

@media (min-width: 525px) and (max-width: 768px) {
  flex-direction: row;
  column-gap: 1em;
  & .child1 {
    width: 50%;
    /* background-color: red; */
  }
  & .child2 {
    width: 50%;
    /* background-color: red; */
    padding-top: 1em;
  }

    
}
@media ${device.tablet} {
  flex-direction: row;

  & .child1,
  .child2 {
    width: 45%;
    /* background-color: pink; */
  }

  & .child2 {
    padding-top: 1.8em;
  }

   

   
}

@media ${device.LaptopL} {
  flex-direction: row;

  & .child1,
  .child2 {
    width: 45%;
    /* background-color: yellow; */
  }

  & .child2 {
    padding-top: 1.8em;
  }

  

 
 }
`;

export default function SingleProductDetails() {
  const show = useContext(CartContext);
  const setShow = useContext(ToggleContext);
  // new import modal
  const [modalIsOpen, setIsOpen] = React.useState(false);


  let { id } = useParams();
  let [product, setProduct] = useState(null);
  let [quantity, setQuantity] = useState(1);
  //const  {products}  = useSelector((state) => state.products);
  const products = useSelector((state) => state.produtoStore); 
// console.log(products)
  const dispatch = useDispatch();

  //increase item quantity
  const increaseQunaity = (e) => {
    e.preventDefault();
    quantity += 1;
    setQuantity(quantity);
  };

  //decrease item quantity
  const decreaseQunaity = (e) => {
    e.preventDefault();
    quantity > 1 ? setQuantity((quantity -= 1)) : setQuantity(quantity);
  };

  //add the item to the cart
  const addToCart = (e) => {
    e.preventDefault();
    let item = {
      id: parseInt(id),
      quantity: quantity,
      price: product.preco,
      img:product.imageNome1, 
      title:product.nome,
    };
    dispatch(addToCarts(item));
  };
 
  //use effect function to find the product from all products
  useEffect(() => {
    let result = products.find((p) => p.id_ === parseInt(id));
    setProduct(result);
  }, [id, products]);


  useEffect(() => {
     
  }, [ ]); 
 
  const images = (product)? [
    (product.imageNome1)?product.imageNome1:"" ,
    (product.imageNome2)?product.imageNome2: "" ,
    (product.imageNome3)?product.imageNome3: "" ,
    (product.imageNome4)?product.imageNome4:"" ]:[];
   
  return (
    <Container>  
       
 
        <ModalView isOpen={modalIsOpen} setIsOpen={() => setIsOpen(false)}>      
          <Carousel size={images.length}>{images}</Carousel>
        </ModalView>
       

      <Flex>
        <div className="child1"> 
          <Carousel size={images.length} functionToCall={setIsOpen}>
            {images}
          </Carousel>
          {/* carousel ends */}
        </div>
        <div >
        <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
            {product && (<> <h2>{product.nome}</h2>
           
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}> 
              <Typography variant="h5"color="#000"  align="left" > Titulo:&nbsp;</Typography>
              <Typography variant="h5"color="palevioletred"  align="center" >   {product.nome}&nbsp;</Typography> 
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}> 
              <Typography variant="h5"color="#000"  align="left" > Preço:&nbsp;</Typography>
              <Typography variant="h5"color="palevioletred"  align="center" >  { MoneyCurrency(product.preco)}&nbsp;</Typography> 
            </Stack>
           
                
                <p>{product.description}</p>

                <ButtonGroup disableElevation  variant="contained"   aria-label="Disabled elevation buttons" >
                  <RemoveCircleOutlineIcon onClick={decreaseQunaity} fontSize={"large"}   /> 
                    <div>
                      <Typography variant="h4"color="palevioletred"  align="center" > &nbsp;{quantity}&nbsp;</Typography> 
                      <Typography color="palevioletred"   >&nbsp;Quantidade&nbsp;</Typography></div>
                     <AddCircleIcon onClick={increaseQunaity}fontSize={"large"}/> 
                </ButtonGroup>
<br/>
                <ButtonGroup disableElevation  variant="contained"   aria-label="Disabled elevation buttons" >
                <Button  onClick={addToCart}>Adicionar ao Carrinho</Button>
                
                <Button href="/cart" style={{background:'palevioletred'}} > Finalizar Compras</Button>
              </ButtonGroup><br/><br/>

              <Link to={`/homepage`} ><Button style= {{background:"#000", color:"#fff", width:"100%" }}  > Continuar comprando</Button></Link>
             
                      </> )}
            </Stack>
      </Grid>
        </div>
      </Flex>
   
      
      
      
    </Container>
  );
}

 ;
