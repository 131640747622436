import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import homeFill from '@iconify/icons-eva/home-fill';
import shopping from '@iconify/icons-eva/shopping-bag-outline';
import shoppingFill from '@iconify/icons-eva/shopping-bag-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from  '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import palette from '../../theme/palette';
//
import account from '../../_mocks_/account';
import { signOut } from '../../store/actions/authActions'
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Page01',
    icon: shoppingFill,
    linkTo: '/cec'
  },
  {
    label: 'Page02',
    icon: shoppingFill,
    linkTo: '/cec2'
  },
  {
    label: 'Page-03',
    icon: shopping,
    linkTo: '/home_03'
  }
];

// ----------------------------------------------------------------------

export default function MenuPages() {  
    const dispatch = useDispatch();

  

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
    function handleSignOut() {
        dispatch(signOut());
    };
  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',          
              position: 'absolute',
               bgcolor:  'rgba(121, 236, 252,0.3)'
            }
          })
        }}
      >
       Pages

      </Button>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
         

     <br/>

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

         
      </MenuPopover>
    </>
  );
}
