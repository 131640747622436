import { useDispatch, useSelector } from "react-redux";
import AudioPlayerMini from "./AudioPlayerMini";
import AudioPlayer from "./AudioPlayer";
import tracks from "./tracks";
import { useCallback, useEffect } from "react";
import { getCamposGenericos } from "../../store/reducers/tbGenerica/camposGenericosSlices";
import AudioPlayButton from "./AudioPlayButton";
export default function RadioPlay(props) {
    
  const { param } = props; 
//   const cGenericaLista = useSelector((state) => state.cGenericosStore); 
//   const listYouTube = cGenericaLista.filter(f=>f.tabelaDesc ==='RadioNazareno')
//   const [records, setRecords] =[listYouTube]; 
//   const dispatch = useDispatch(); 
//   const initFetch = useCallback(() => {
//     dispatch(getCamposGenericos());  
    
//     }, [dispatch])
  
//     useEffect(() => {
//       initFetch()
//     }, [initFetch])
// console.log(records)

// const src =records?records.map(
//     function(i, indice){      
//        return{         
//             title: i.campo_adic_02,
//             artist: i.campo_adic_03,
//             audioSrc: i.campo_adic_01,
//             image: 'imgSrc',
//             color: "#00aeb0"
//              }
//     }):'';
    // console.log(src)
//   {
//     title: "Cali",
//     artist: "Wataboi",
//     audioSrc: cali,
//     image: imgSrc,
//     color: "#00aeb0"
//   }

const sintonia= [
  {
    title: "Radio Nazareno",
    artist: "107.9",
    audioSrc: "https://stm1.onecast.com.br:7836/stream",
    image: 'imgSrc',
    color: "#00aeb0"
  }];
    return (
        <div  >
        {/* {(src!='' &&src!=null)&&
               <>
                {(param =='button')&& <AudioPlayButton tracks={src}/> }
                {(param =='mini')&& <AudioPlayerMini tracks={src} />}
                {(param =='normal')&& <AudioPlayer tracks={sintonia} />}
               
                </>
         } */}
         {(param =='normal')&& <AudioPlayer tracks={sintonia} />}
        </div>
        
    )
};