import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const usertoken = localStorage.getItem("token");
const userUser = localStorage.getItem("user");
const userId = localStorage.getItem("_id");
const userFoto = localStorage.getItem("foto");
const userPassword = localStorage.getItem("password");
const userFuncao = localStorage.getItem("funcao");
const initialState = {
  token: null,// localStorage.getItem("token"),
  name: null,
  email: null,
  _id: null,
  msn:null,
  password:null,
  foto:null,
  funcao:null,
  
};

const authReducer = (state = ((usertoken == null) ? initialState : {
    token: jwtDecode(usertoken),
    name: userUser,
    email: userUser,
    _id: userId,
    foto:userFoto,
    password: userPassword,
    funcao:userFuncao,
}), action) => {
    const mensagem = "Bem vindo " + action.name + '  vc esta Logado...';
    switch (action.type) {        
        case "SIGN_IN":
        case "SIGN_UP":
        case "USER_LOADED":
          toast.success(mensagem, {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#2dc653', color:'#fff'},  icon:'🚀', })
        //     toast.info(mensagem, {
        // position: toast.POSITION.BOTTOM_RIGHT,
        //   });
          const user = jwtDecode(action.token);
      return {
        ...initialState,
          token: user,
          name: action.name,
          email: action.email,
          _id: action._id,
          password: action.password,
          foto: action.foto,
          funcao:action.funcao,
   
          
      }; 
    case "SIGN_ERROR":
      //localStorage.setItem("errorLogin",'deu mau');
      toast.error("errou ao logar!..."+JSON.stringify(action.msn), {
        position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#24282b', color:'#fff'}
      });      
   return {
    ...initialState,
     msn:action.msn,
     
    };
    case "SIGN_OUT":
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("_id");
            toast.error("Goodbye... volte sempre!...", {
        position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#24282b', color:'#fff'}
      });      
       
      return {
        token: null,
        name: null,
        email: null,
        _id: null,
      };
    default:
      return state;
  }
};

export default authReducer;
