import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter, Link, Navigate, useRoutes} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"; 
//Pages
 
import Login from './pages/Login'; 
import NotFound from './pages/Page404'; 
// // ----------------------------------------------------------------------
import DefaultHomepage from './layouts/DefaultHomepage';
import LogoOnlyLayout from './layouts/LogoOnlyLayout'; 
import Homepage from './pages/Homepage'; 
import SliderAdm from './pages/SliderAdm'; 
import AdmProdutos from './pages/AdmProdutos'; 
import SingleProductDetails from "./pages/Products/ProductDetails/SingleProductDetails"; 
import UserAdm from './pages/UserAdm';
import ThemaOnlyHeader from './layouts/ThemaOnlyHeader';  
import TbGenericaAdm from './pages/TbGenericaAdm';
import CamposGenericos from './pages/CamposGenericos'; 
import Radio from './pages/Radio';



const userLogado = localStorage.getItem("userLog");
export default function Router() {
  const user = useSelector((state) => state.auth);
  
  function PrivateRoute({ children }) {
    const auth = useAuth();
    return auth ? children : <Navigate to="/users/login" />;
  }
  function useAuth() {   
    if (user._id!==null ) { return true}
    else{return false};
    
  }

  //console.log('status do Usuario: ',user );
    function AdminRoute({ children }) {
    const authAdm = useAdmin();
    return authAdm ? children : <Navigate to="/users/login" />;
  }
  function useAdmin() {   
    if (user.funcao=="Admin" ) { return true}
    else{return false};
    
  }
  return useRoutes([
    {
      path: '/',
      element: <DefaultHomepage />,
      children: [
       
        { path: '/', element: <Navigate to="/home" replace /> },   
        { path: 'radionazareno', element: <Radio /> },
        { path: 'home', element: <Homepage /> },            
        {path: '404', element: <NotFound/>},        
      ]
    }, 
    {
      path: '/users/',    
      // element: <DefaultHomepage />,
      children: [ 
        { path: 'login', element: <Login /> }, 
      ]
    },
    {
      path: '/adm/',   
      element: <DefaultHomepage />, 
      children: [ 
        { path: 'users', element:<AdminRoute> <UserAdm /></AdminRoute>},
        {path: 'admprodutos', element: <AdminRoute> <AdmProdutos />  </AdminRoute> },
        {path: 'admsliders', element: <AdminRoute> <SliderAdm />  </AdminRoute> }, 
        {path:'tbgenerica', element:<AdminRoute> <TbGenericaAdm />  </AdminRoute>},
        {path:'camposgenericos', element:<AdminRoute> <CamposGenericos />  </AdminRoute>}
         
      ]
    },   

    { path: '*', element: <Navigate to="/404" replace /> }

    
  ]);
}
