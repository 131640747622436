import React from 'react';
 
import { toast } from 'react-toastify';
import {Box, Card, Grid, Typography, Avatar, CardMedia, autocompleteClasses,
    Paper,  Container, Table, TableHead, TableContainer,TableRow, TableCell, TableBody, ButtonGroup, Button, InputAdornment, Toolbar } from '@mui/material';
export default function Toast() {
    const handleToast = (id_) => {
     

    }
 return (
   <div>
    {/* testes */}
     
    <Button onClick={() =>toast('Mensagem customizada', {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#6c63fe', color:'#fff'},  icon:'🚀', })} > Default_</Button>
       <Button onClick={() =>toast.success('Sucesso Customizado', {style:{backgroundColor:'#2dc653', color:'#fff'},  icon:'🚀', })} > sucesso1</Button>
       <Button onClick={() =>toast('Sucesso Customizado', {style:{backgroundColor:'#77cccc', color:'#fff'},  icon:'🚀', })} > sucesso</Button>
       <Button onClick={() =>toast('Sucesso Customizado', {style:{backgroundColor:'#546a7b', color:'#fff'},  icon:'🚀', })} > Gray</Button>
       <Button onClick={() =>toast.warn('Sucesso Customizado', {style:{backgroundColor:'#fc6023', color:'#fff'},  icon:'🚀', })} > Worn</Button>
       <Button onClick={() =>toast.warn('Sucesso Customizado', {style:{backgroundColor:'#fc6023', color:'#ffd500'},  icon:'🚀', })} > Atenção</Button>
       <Button onClick={() =>toast.error('Sucesso Customizado', {style:{backgroundColor:'#f66b92', color:'#fff'},  icon:'🚀', })} > ERROR</Button>
       <Button onClick={() =>toast.error('Sucesso Customizado', {style:{backgroundColor:'#24282b', color:'#fff'},  icon:'🚀', })} > black</Button>
      
       <Button onClick={() => handleToast(toast.info('Mensagem customizada', { position: toast.POSITION.BOTTOM_RIGHT, theme:"colored",} ))} > Info</Button>
       {/* testfim */}

   </div>
 );
}