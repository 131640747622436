
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState,   useCallback } from "react"; 
import {Box, Card, Grid, Typography, Avatar, CardMedia, autocompleteClasses,FormControl, InputLabel, MenuItem, Select,
  Paper,  Container, Table, TableHead, TableContainer,TableRow, TableCell, TableBody, ButtonGroup, Button, InputAdornment, Toolbar, Stack, ListItemIcon, Checkbox, ListItemText, TextField, Autocomplete } from '@mui/material'; 
import { styled } from "@mui/material";
 
import DeleteOutlineIcon  from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Page from '../components/Page';
import '../components/utilidades/StylePage.css'; 
import { toast } from 'react-toastify';
 

// import {getTbGenericaNew} from "../store/reducers/TbGenerica/getTbGenericaSlice";

import {DataGrid /*,  GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarFilterButton, GridToolbarDensitySelector */ } from '@mui/x-data-grid';
import { deleteCamposGenericos, getCamposGenericos } from "../store/reducers/tbGenerica/camposGenericosSlices";
import CamposGenericosForm from "../components/tbGenerica/CamposGenericosForm";
import { MenuProps, useStyles } from "../components/utilidades/utils";
import { getTbGenerica } from "../store/reducers/tbGenerica/tbGenericasSlice";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const CardForm = styled('div')({ 
  display: 'flex',   
  marginTop:'-40px', 
  backgroundColor:"transparent",
  width:'100%'
});
const Card_ = styled(Card)({
  padding:"15px",
  backgroundColor:"#fff",
  marginLeft: "30px",
  transition: "0.3s",
  boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  "&:hover": {
    boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
  }
});

const RootStyle = styled(Page)(() => ({
  minHeight:'150vh',   
  alignItems: 'center', 
  
  // backgroundImage: "-webkit-linear-gradient(0deg, rgba(203, 228, 255,1) 50%, rgba(21, 37, 53,1) 50%)",
   
}));
const StyleTriangulo = styled(Card)(() => ({
  minHeight:'160vh',
  display: 'flex',    
  marginTop:'-120px',
  alignItems: 'center',
  padding: '100px 20px 10px 20px',
  
  // backgroundImage: "-webkit-linear-gradient(0deg, rgba(203, 228, 255,1) 50%, rgba(21, 37, 53,1) 50%)",
   
}));




 
export default function CamposGenericos() {
  const [currentWidth, setCurrentWidth]= useState(0);
  const [currentId, setCurrentId] = useState(0); 
  const [autocompleteValue, setAutocompleteValue] = useState();
  const [selected, setSelected] = useState([]);
  const cGenericaLista = useSelector((state) => state.cGenericosStore);
  const TbGenericaLista = useSelector((state) => state.tbGenericaStore);
  const dispatch = useDispatch(); 

  //Select tab generica  
  const TbGenericas = TbGenericaLista.map((itemm, index, array) => { 
    return itemm.tabelaDesc;
  })
  const [recordsTabList, setRecordsTabList] =[TbGenericas];  
  //Fim Select tab generica 
  const [recordsTab, setRecordsTab] =[TbGenericaLista];  
   const [records, setRecords] =[cGenericaLista]; 
  const [loadingg , setLoadingg]=useState(false);
 

 const initFetch = useCallback(() => {
  dispatch(getCamposGenericos()); 
  dispatch(getTbGenerica()); 
    setCurrentWidth(100);   
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])
 
 const paramfilter = (selected) ? selected.id :'0';
 const headers =recordsTab? (recordsTab).filter(f=>  f.id===paramfilter ).map(_=>_):'';
//  console.log('recordsTab ==>'+JSON.stringify(recordsTab))
//  console.log('selected  =>'+JSON.stringify(selected))
//  console.log('paramfilter  =>'+JSON.stringify(paramfilter))
//  console.log('Headers  =>'+JSON.stringify(headers))

   const colunas = headers.length? [ 
                { field: 'id', headerName: 'Id' , width:80},
                { field: 'tabelaDesc', headerName:"Tabela - Titulo" , width:150},
                { field: 'campo_adic_01', headerName: headers[0]["campo_adic_01"], width:150 },
                { field: 'campo_adic_02', headerName:  headers[0]["campo_adic_02"] , width:110  },
                { field: 'campo_adic_03', headerName:  headers[0]["campo_adic_03"], flex: 1 },
                { field: 'campo_adic_04', headerName:  headers[0]["campo_adic_04"], flex: 1 },
                { field: 'campo_adic_05', headerName:  headers[0]["campo_adic_05"], flex: 1 },
                { field: 'campo_adic_06', headerName:  headers[0]["campo_adic_06"], flex: 1 },
                // Ações
                { field: 'id_', headerName: 'AÇÃO' ,   width:120, renderCell: (cellValues) => {
                  return (
                    <ButtonGroup variant="text">                
                    <Button><EditIcon color="primary"  onClick={() => {setCurrentId(cellValues.value)}} /></Button>
                    <Button><DeleteOutlineIcon sx={{ color: 'palevioletred' }} onClick={() => removeTbGenerica(cellValues.value)} /></Button>                     
                    </ButtonGroup>                       
                  );
                }   },
              ] :[]
              // [ {  headerName:
              //         <Stack backgroundColor="#d8fbde" height={30} direction={{ xs: 'column', sm: 'row' }}   justifyContent="center" alignItems="center" color="rgb(10, 85, 84)">  
              //           <SentimentVeryDissatisfiedIcon  color="rgb(54, 179, 126)"    />&nbsp; Tabela não Identificada.... &nbsp;  
              //         </Stack> 
              //         , width:300}
              //    ,]       
        ; 
const removeTbGenerica = Id => {
      if (window.confirm('Voce tem certeza que quer deletar?'))     
          dispatch(deleteCamposGenericos({ id: Id }))
          .unwrap()
          .then(data => {
             
            toast.error(data, {style:{backgroundColor:'#f66b92', color:'#fff'},  icon:'🚀', })
            // console.log(data);
            dispatch(getCamposGenericos());            
          })
          .catch(e => {
            console.log(e);
          });
        } 


  var leg = records.length;
  const maxheigthGrid = (leg>=9)?(350):((leg<=2)?(350):((leg*80)));
   const PaperCard = styled(Card)(({ theme }) => ({
     ...theme.typography.body2,
     padding: theme.spacing(0.1),
     fontSize:'8px',
     marginLeft:'10px',
     lineHeight:0.1,
     //maxheight: 800, 
     height: maxheigthGrid,
     width: '97%',
     background: 'rgba(255, 255, 255, 0.8)', 
     color:'#fff'     
   })); 

  // console.log(currentId)
  // Custom Select
 
 // console.log(records.map(_=>_.tabelaId))
return(
  
<RootStyle>

<StyleTriangulo className={'backLinearGradiBordo'}  >

<Grid container  spacing={3} style={{position:'absolute', zIndex:'2'}} > 


 
<Grid item xs={12} >
      <Stack  height={280} direction={{ xs: 'column', sm: 'row' }}      alignContent='center' >  
          <img style={{marginLeft:'25vw', marginTop:'-30px'}}  height={280} width="auto" src="/static/img/image22.png" alt="admVector"/>&nbsp;
          <div style={{fontSize:'40px', fontWeight:'900'}}   color="rgba(51, 52, 54,1)"> <br/>  Campos Genericos</div>  
      </Stack>
      
 </Grid>
 <Grid item xs={12}>
      <Stack  height={70} direction={{ xs: 'column', sm: 'row' }}   alignContent='center' style={{margin:'-80px 5px 50px 5px'}}  justifyContent="center" alignItems="center">
        {/*================ Mult Select ==================================================================*/}
          <Typography variant="h4">Selecione uma Tabela:&nbsp;&nbsp; </Typography>
           <Autocomplete
                     // multiple 
                      //onChange={(e, newValue) => {setSelected(newValue.tabelaDesc) }}
                      onChange={(e, newValue) => {setSelected(newValue) }}
                      MenuProps={MenuProps}
                      id="tags-standard"
                      options={recordsTab}
                      getOptionLabel={(option) => option.tabelaDesc}
                      style={{ width: 350 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Tabelas Genericas" variant="outlined" />
                      )}
                    />
        {/* ===============Fim Select===================================================================== */}
      </Stack>
 </Grid>
 {headers.length?
 <CardForm>
    <Grid item  xs={4}  >
      <Card_  className='imgBoxShadow' >      
     
       <CamposGenericosForm {...({ currentId, setCurrentId , paramfilter, headers})}/>
       
      </Card_>
    </Grid>
    <Grid item  xs={7}   >
  

{/* Lista de Dados  style={{color:'#fff'}}*/}

     <PaperCard  >  
      {loadingg&&<p>Processando...por favor aguardar...</p>}
         <DataGrid   
          rows={records.filter(f=>  f.tabelaId===paramfilter ).map((item, index) => ({
            // id:index,   
            id:item.id,  
            id_:item.id_,  
            tabelaDesc: item.tabelaDesc,
            campo_adic_01: item.campo_adic_01,     
            campo_adic_02: item.campo_adic_02,
            campo_adic_03: item.campo_adic_03,
            campo_adic_04: item.campo_adic_04,
            campo_adic_05: item.campo_adic_05
          }))}
         
           columns={colunas} loading={loadingg } hideFooter/> 
 
       </PaperCard>

       {/* <Toast/> */}


     
    </Grid>   
   </CardForm>:
   <Container>
      <Stack backgroundColor="#d8fbde" height={30} direction={{ xs: 'column', sm: 'row' }}   justifyContent="center"  
      alignItems="center" color="rgb(10, 85, 84)" style={{fontSize:'20px', width:'500px', fontWeight:'900'}}>  
      <SentimentVeryDissatisfiedIcon  color="rgb(54, 179, 126)"    />&nbsp; Selecione uma Tabela.... &nbsp;  
      </Stack> 
    </Container>}
</Grid>
    
<div className={'backLinearGradi backTrianguleGradi'} ></div>
</StyleTriangulo>
 
 </RootStyle>
 
);
  
 
}