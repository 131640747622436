
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState,   useCallback } from "react"; 
import {Box, Card, Grid, Typography, Avatar, CardMedia, autocompleteClasses,
  Paper,  Container, Table, TableHead, TableContainer,TableRow, TableCell, TableBody, ButtonGroup, Button, InputAdornment, Toolbar } from '@mui/material'; 
import { styled } from "@mui/material";
 
import DeleteOutlineIcon  from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Page from '../components/Page';
import '../components/utilidades/StylePage.css';
import ProdutoAdminForm from '../components/produto/ProdutoAdminForm' ;
import { getProdutos, updateProduto, deleteProduto } from "../store/reducers/produto/produtosSlice";
import { toast } from 'react-toastify';

import {DataGrid /*,  GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarFilterButton, GridToolbarDensitySelector */ } from '@mui/x-data-grid';
import { id } from "date-fns/locale";
import Toast from "../components/utilidades/Toast";
import api from "../api/axios";

 
// backBobble

const ContentStyle = styled('div')(({ theme }) => ({
  width: '99%',
  fontSize:'calc(1em * 2)',
  margin: 'auto',
  //  display: 'flex',
  height: "370px",
  // flexDirection: 'column',
  // justifyContent: 'center',
}));
const Card_ = styled(Card)({
 
    // backgroundImage: "-webkit-linear-gradient(0deg, #ffffff 30%, rgba(173, 215, 227,1) 70%)",
    minHeight: "85vh",

    width: "90vw",
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  });
  const RootStyle = styled(Page)(({ theme }) => ({
    minHeight:'100vh',
    display: 'flex',    
    marginTop:'-120px',
    alignItems: 'center',
    padding: '100px 20px 10px 20px',
    
    // backgroundImage: "-webkit-linear-gradient(0deg, rgba(203, 228, 255,1) 50%, rgba(21, 37, 53,1) 50%)",
     
  }));


 
export default function AdmProdutos() {
  const [currentWidth, setCurrentWidth]= useState(0);
  const [currentId, setCurrentId] = useState(0); 
  

  const produtosLista = useSelector((state) => state.produtoStore);
  // const produtosLista = useSelector((state) => state.produtosNew.data);
  const dispatch = useDispatch(); 
  
    // const loadin= useSelector((state) => state.loading); 
  // const records = produtosLista;
  // const records = produtosLista;
   const [records, setRecords] =[produtosLista]; 
  const [loadingg , setLoadingg]=useState(false);
  console.log(produtosLista)


  const initFetch = useCallback(() => {
    dispatch(getProdutos()); 
    setCurrentWidth(100);   
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])
 
 
   const colunas =[  
  { field: 'produtoId', headerName: 'ID' , width:30},
  // { field: 'id_', headerName: 'id_' , width:30},
                { field: 'nome', headerName: 'NOME' , flex: 1 },
                { field: 'descricao', headerName: 'DESCRIÇÃO' , flex: 1 },
                { field: 'categoriaId', headerName: 'CATEGORIA' , flex: 1 },
                { field: 'imageNome2', headerName: 'IMAGEM' ,   width:120, renderCell: (cellValues) => {
                  return (  
                    <Card sx={{ width: 120, height: 60}}><CardMedia style={{width:"auto"}} component="img"  height="60"   image={cellValues.value} alt="img"/></Card>
                  );
                }   }  ,
                
                { field: 'id_', headerName: 'AÇÃO' ,   width:120, renderCell: (cellValues) => {
                  return (
                    <ButtonGroup variant="text">
                
                    <Button><EditIcon color="primary"  onClick={() => {setCurrentId(cellValues.value)}} /></Button>
                    <Button><DeleteOutlineIcon sx={{ color: 'palevioletred' }} onClick={() => removeProduto(cellValues.value)} /></Button>                     
                    </ButtonGroup>                       
                  );
                }   },            
              ];
             // console.log(JSON.stringify(currentId));
const removeProduto = id_ => {
      if (window.confirm('Voce tem certeza que quer deletar?'))
          // dispatch(actions.Delete(id, () => addToast("Deletado com Sucesso!", { appearance: 'error'/*'success','warning','info' */})))
          //  console.log(id_);
          dispatch(deleteProduto({ id: id_ }))
          .unwrap()
          .then(data => {
             
            toast.error(data, {style:{backgroundColor:'#f66b92', color:'#fff'},  icon:'🚀', })
            // console.log(data);
            dispatch(getProdutos());            
          })
          .catch(e => {
            console.log(e);
          });
        }


 var leg =records.length;
 const maxheigthGrid =  (leg>=15)?(600):((leg<=2)?(350):((leg*80)));
  const PaperCard = styled(Card)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.1),
    fontSize:'8px',
    marginLeft:'10px',
    lineHeight:0.1,
    //maxheight: 800, 
    height: maxheigthGrid,
    width: '97%'
     //color: theme.palette.text.secondary,
  // background:"#fdfce1", /* theme.palette.gradients.grey,*/
   
  }));
  
  
 
// console.log(JSON.stringify(records));

return(
<RootStyle className={'backBobble3'}   >

   <Card_ >
      
   <Grid container item style={{display: 'flex',  justifyContent: 'center'}}  > 
 
    <Grid item  xs={3}  style={{minWidth:'300px'}}>
      <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
      
        
      </Box>
      <Card style={{margin:'2px 10px 2px 20px'}}  className='imgBoxShadow'>      
      <ProdutoAdminForm {...({ currentId, setCurrentId })}/>
      </Card>
      
    </Grid>
    <Grid item  xs={9} style={{backgroundColor:'rgba(173, 215, 227,1)', height:'80vh',minWidth:'300px'}} > 


  
 
      <PaperCard  className='imgBoxShadow'>  
      {loadingg&&<p>Processando...por favor aguardar...</p>}
         <DataGrid  
          rows={records.map((item, index) => ({
            id:index,    
            id_:item.id_,     
            nome: item.nome,
            descricao: item.descricao,            
            categoriaId: item.categoriaId,
            imageNome2: item.imageNome2
          }))}
         
           columns={colunas} loading={loadingg } hideFooter/>

  
 
       </PaperCard>

       <Toast/>
      
    </Grid>
   </Grid>
      
 {/* {JSON.stringify(records)} */}
   
    </Card_>
</RootStyle>
 
);
  
 
}