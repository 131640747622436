import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import { Grid,Card,  Box,Typography, TextField,  Button, Stack, IconButton, InputAdornment,Avatar, Fab,Input } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import  '../card/styleCard.css';
import { Favorite as FavoriteIcon, KeyboardBackspace as VoltarIcon, ShoppingCartCheckout as cartIcon} from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
 
import { toast } from 'react-toastify';
import AlertDialogMUI from "../sweetAlerts/AlertDialogMUI";
import {  getTbGenerica } from "../../store/reducers/tbGenerica/tbGenericasSlice";
import { createCamposGenericos, getCamposGenericos, updateCamposGenericos } from "../../store/reducers/tbGenerica/camposGenericosSlices";
 
export default function CamposGenericosForm(props)   {  
  const { currentId, setCurrentId, paramfilter, headers } = props;  
  const [submitted, setSubmitted] = useState('inicio');
 
 
  const [values_, setValues_] = useState([""]);
  const tbGenericaListaCampos = useSelector((state) => state.cGenericosStore);  
 
  const dispatch = useDispatch();  
 
  const initFetch = useCallback(() => {
                    dispatch(getTbGenerica());    
  }, [dispatch])

  useEffect(() => {
                    initFetch();
                    if(currentId!=0 ||currentId!=""){ 
                      setSubmitted(false); 
                      setValues({...tbGenericaListaCampos.find(x => x.id == currentId)});    
                                             
                    }
  }, [initFetch, currentId])
 
    
  const onSubmit = (values) => {
    
       const formData = new FormData();
       formData.append("TabelaId", paramfilter);
        formData.append("TabelaDesc", headers?headers[0].tabelaDesc:'');
        formData.append("Campo_adic_01", values.campo_adic_01);
        formData.append("Campo_adic_02", values.campo_adic_02);
        formData.append("Campo_adic_03", values.campo_adic_03); 
        formData.append("Campo_adic_04", values.campo_adic_04);
        formData.append("Campo_adic_05", values.campo_adic_05);
        formData.append("Campo_adic_06", values.campo_adic_06);  
        //  console.log(currentId)

        
        
        if (currentId != null&&currentId !=""&&currentId!=0){
          //Função Edite
       dispatch( updateCamposGenericos({ id: currentId, data: formData }) )  
       .unwrap()
       .then(data => {         
           // =====Show Message ==================================================================================================================
                      //"foguete","successAzul","successAzulAnim","successVerd","successAzulClaro","deleteC","deleteAnimation"
                      //"deleteOk","errorGif","excluir","confete","deleteC","erroEco","question"
                      //"bodySuccess", "bodySuccess2", "bodySuccess3","bodySuccess4","bodyconfete","bodyQuestion","bodyDelete","bodyDeleteOk","bodyErroFatal"
 
                      dispatch({
                       type: "SHOW_MESSAGE",   
                       msn:JSON.stringify(data), 
                       open:true,
                       classeParam:"confete" ,  
                       temaAlert:"bodyconfete"                   
                      });
                      setTimeout(()=>{ dispatch({type: "HIDE_MESSAGE"}); },500)
     // =====Show Message ==================================================================================================================  
         resetForm();
         setCurrentId(0);
         dispatch(getCamposGenericos());
         setSubmitted(true);
         toast( 'Editado com Sucesso!', {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#6c63fe', color:'#fff'},  icon:'🚀', });
         console.log(data);
      
       } )
       .catch(e => {
         console.log(e);
       });
       }
       else
       {
         // dispatch(actions.create(formData, onSuccess ));
        //Função Add
       dispatch(createCamposGenericos(formData))
        .unwrap()
        .then(data => {     
          console.log(data.statusCod) 
         console.log(data.Mensagem) 
          console.log(data) 
         
         if(data.statusCod==="200"){
             // =====Show Message ==================================================================================================================
                      //"foguete","successAzul","successAzulAnim","successVerd","successAzulClaro","deleteC","deleteAnimation"
                      //"deleteOk","errorGif","excluir","confete","deleteC","erroEco","question"
                      //"bodySuccess", "bodySuccess2", "bodySuccess3","bodySuccess4","bodyconfete","bodyQuestion","bodyDelete","bodyDeleteOk","bodyErroFatal"
 
                      dispatch({
                       type: "SHOW_MESSAGE",   
                       msn:JSON.stringify(data.Mensagem), 
                       open:true,
                       classeParam:"confete" ,  
                       temaAlert:"bodyconfete"                   
                      });
                      setTimeout(()=>{ dispatch({type: "HIDE_MESSAGE"}); },500)
     // =====Show Message ==================================================================================================================  
             resetForm();
             dispatch(getCamposGenericos());
             setSubmitted(true);
             toast( data, {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#6c63fe', color:'#fff'},  icon:'🚀', });
         }else{
           dispatch({
             type: "SHOW_MESSAGE",   
             msn:JSON.stringify(data.Mensagem), 
             open:true,
             classeParam:"erroEco" ,  
             temaAlert:"bodyErroFatal"                   
            });
            setTimeout(()=>{ dispatch({type: "HIDE_MESSAGE"}); },500)
         }
         
          
          // console.log(data);
        })
        .catch(e => {
          console.log(e);
          });
         }    
         
  };
   
  const newSlider = () => { 
    setSubmitted(false);
  };
  const closeSlider = () => { 
    setSubmitted('fechado');
 
  };
 
const CrudSchema = Yup.object().shape({
 //custom validações aqui
  
});
 
 

const formik = useFormik({
  initialValues: {
    //tabelaId :"",
    tabelaDesc: "",
    campo_adic_01: "",
    campo_adic_02: "",
    campo_adic_03: "", 
    campo_adic_04: "",
    campo_adic_05:"",
    campo_adic_06:"" 
  },
validationSchema: CrudSchema,    onSubmit,});   
const {errors, touched, values, handleSubmit, isSubmitting, getFieldProps, resetForm, setValues, setErrors, setFieldValue 
} = formik;
  return (    
    <Stack padding={3}>
      
    
      
          <AlertDialogMUI />
      { (submitted=== false) ?(
  
        <Stack spacing={1}  >
           <Stack direction={{ xs: 'column', sm: 'row' }}  spacing={1} textAlign={'right'} >
          <Typography variant="h4">{headers?headers[0].tabelaDesc:''}</Typography>
           <Box sx={{ flexGrow: 1 }} >
           {/* <Fab style={{fontSize: 15 }} onClick={closeSlider} className="iconButton"  size="small" component="span"  >x</Fab> */}
           <IconButton className="iconButton"  aria-label="add to favorites" onClick={closeSlider}>
            
            <ClearIcon /></IconButton> 
  
           </Box>
      </Stack>
 <FormikProvider value={formik}>
   <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
   <Stack spacing={1}>
 
         <TextField fullWidth autoComplete="campo_adic_01" label={headers?headers[0].campo_adic_01:''} {...getFieldProps('campo_adic_01')}  />
         <TextField fullWidth autoComplete="campo_adic_02" label={headers?headers[0].campo_adic_02:''} {...getFieldProps('campo_adic_02')}  />
         <TextField fullWidth autoComplete="campo_adic_03" label={headers?headers[0].campo_adic_03:''}{...getFieldProps('campo_adic_03')}  />
         <TextField fullWidth autoComplete="campo_adic_04" label={headers?headers[0].campo_adic_04:''} {...getFieldProps('campo_adic_04')}  />
         <TextField fullWidth autoComplete="campo_adic_05" label={headers?headers[0].campo_adic_05:''}{...getFieldProps('campo_adic_05')}  />
         <TextField fullWidth autoComplete="campo_adic_06" label={headers?headers[0].campo_adic_06:''}{...getFieldProps('campo_adic_06')}  />     
 
          
          <LoadingButton fullWidth size="large" type="submit" variant="contained" > Salvar </LoadingButton>
          <LoadingButton fullWidth size="large" color='warning' variant="contained" loading={isSubmitting}onClick={() => { resetForm() }}>Limpar</LoadingButton>
          </Stack>
      </Form>
    </FormikProvider>
         
    </Stack>
   
      ): (
        <div> 
          {(submitted === 'inicio' || submitted ==='fechado') ?

          ( <Typography variant="h6" letterSpacing={2} >Cadastrar </Typography>):
          ( <Typography variant="h6" letterSpacing={2} >  Registro Efetivado com Sucesso</Typography>)}
          <Button fullWidth size="large" type="submit" variant="contained"   onClick={newSlider} > NOVO </Button>
          
        </div>
      )
      }
    </Stack>
    
  );

};

 