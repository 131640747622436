import React, { useState, useEffect } from 'react' 
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import { styled } from "@mui/material";

const IconButton_ = styled(IconButton)(({ theme }) => ({
    zIndex: 2,
    position: 'fixed',
    bottom: '2vh',
    backgroundColor: 'rgba(108, 99, 254, 0.49)',
    color: '#fff',
    "&:hover, &.Mui-focusVisible": {
        transition: '0.3s',
        color: '#fff',
        backgroundColor: '#130b32'},
        [theme.breakpoints.up('xs')]: {
            right: '5%',
            backgroundColor: 'rgba(108, 99, 254, 0.49)',
        },
        [theme.breakpoints.up('lg')]: {
            right: '6.5%',
        },

  }));

 

const Scroll = ({
    showBelow,
}) => {

    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    return (
        <div>
            {show &&
                <IconButton_ onClick={handleClick}  aria-label="to top" component="span">
                    <ExpandLessIcon />
                </IconButton_>
            }
        </div>
    )
}
export default Scroll