import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/axios";
const initialState = [];


const getAll = () => {
  return api.get("/sliders/SlidersList");
};
const create = data => {
 return api.post('/sliders/novoSlider/', data);
};
const update = (id, data) => {
  return api.put(`/sliders/${id}`, data);
};

const remove = id => {
  return api.delete(`/sliders/${id}`);
};
const removeAll = () => {
  return api.delete(`/sliders`);
};

const findByTitle = title => {
  return api.get(`/sliders?title=${title}`);
};

// daqui

export const createSlider = createAsyncThunk(
  "sliders/novoSlider",
  async (formData) => {
    // console.log(formData.get('Titulo'));
    const res = await create(formData);
    
    return res.data;
  }
);
export const getSliders = createAsyncThunk(
  "sliders/SlidersList",
  async () => {
    const res = await getAll();
    return res.data;
  }
);

export const updateSlider = createAsyncThunk(
  "sliders/update",
  async ({ id, data }) => {
      // console.log(data.get('ImagemUpload'));
    const res = await update(id, data);
    return res.data;
  }
);

export const deleteSlider = createAsyncThunk(
  "sliders/delete",
  async ({ id }) => {
    const resposta = await remove(id);
    return resposta.data;
  }
);

export const deleteAllSliders = createAsyncThunk(
  "sliders/deleteAll",
  async () => {
    const res = await removeAll();
    return res.data;
  }
);

export const findSlidersByTitle = createAsyncThunk(
  "sliders/findByTitle",
  async ({ title }) => {
    const res = await findByTitle(title);
    return res.data;
  }
);

const sliderSlice = createSlice({
  name: "slider",
  initialState,
  extraReducers: {
 
    [createSlider.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
   
    [getSliders.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    
    [updateSlider.fulfilled]: (state, action) => {
      const index = state.findIndex(slider => slider.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deleteSlider.fulfilled]: (state, action) => {
      let index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [deleteAllSliders.fulfilled]: (state, action) => {
      return [];
    },
    [findSlidersByTitle.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = sliderSlice;
export default reducer;