import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSlider, updateSlider } from "../../store/reducers/Sliders/slidersSlice";
import { Grid,Card,  Box,Typography, TextField,  Button, Stack, IconButton, InputAdornment,Avatar, Fab,Input } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import  '../card/styleCard.css';
import { Favorite as FavoriteIcon, KeyboardBackspace as VoltarIcon, ShoppingCartCheckout as cartIcon} from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { getSliders } from "../../store/reducers/Sliders/slidersSlice";
import { toast } from 'react-toastify';
import AlertDialogMUI from "../sweetAlerts/AlertDialogMUI";
 
export default function SliderAdminForm(props)   {  
  const { currentId, setCurrentId,autocompleteValue } = props;  
  const [submitted, setSubmitted] = useState('inicio');
  const [images, setImages] = useState(null);
  const [fille, setFille] = useState();
  const [values_, setValues_] = useState([""]);
  const slidersLista = useSelector((state) => state.sliderStore); 

  //const sliderCurrent = slidersLista.filter((b) => b.id ==currentId )[0];
console.log(autocompleteValue)
  const dispatch = useDispatch();
 
   
  const initFetch = useCallback(() => {
                    dispatch(getSliders());    
  }, [dispatch])

  useEffect(() => {
                    initFetch();
                    if(currentId>=1){ 
                      setSubmitted(false); 
                      //Passa dados do item selecionado para Edição
                      setValues({...slidersLista.find(x => x.id == currentId)});    
                      setValues_({
                        ...slidersLista.find(x => x.id == currentId)
                       });                        
                    }
  }, [initFetch, currentId])
 
  const onChangeImg = (e) => { 
    setImages(URL.createObjectURL(e.target.files[0]));   
    //novo
    e.preventDefault();
    setFille(e.target.files[0]); 
  };
 
  const onSubmit = (values) => {
    const Imag  =fille;
       const formData = new FormData();
        formData.append("Titulo", values.titulo);
        formData.append("SubTitulo", values.subTitulo);
        formData.append("Descricao", values.descricao);
        formData.append("SubDescricao", values.subDescricao); 
        formData.append("ImageNome", values.imageNome);
        formData.append("Imagem", values_.imagem);
        formData.append("ImagemUpload", Imag); 
        formData.append("CampoExtra_03", autocompleteValue);
  
     
      if (currentId != null&&currentId !=""&&currentId!=0){
         //Função Edite
      dispatch( updateSlider({ id: currentId, data: formData }) )  
      .unwrap()
      .then(data => {         
          // =====Show Message ==================================================================================================================
                     //"foguete","successAzul","successAzulAnim","successVerd","successAzulClaro","deleteC","deleteAnimation"
                     //"deleteOk","errorGif","excluir","confete","deleteC","erroEco","question"
                     //"bodySuccess", "bodySuccess2", "bodySuccess3","bodySuccess4","bodyconfete","bodyQuestion","bodyDelete","bodyDeleteOk","bodyErroFatal"

                     dispatch({
                      type: "SHOW_MESSAGE",   
                      msn:JSON.stringify(data), 
                      open:true,
                      classeParam:"confete" ,  
                      temaAlert:"bodyconfete"                   
                     });
                     setTimeout(()=>{ dispatch({type: "HIDE_MESSAGE"}); },500)
    // =====Show Message ==================================================================================================================  
        resetForm();
        setCurrentId(0);
        dispatch(getSliders());
        setSubmitted(true);
        toast( 'Editado com Sucesso!', {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#6c63fe', color:'#fff'},  icon:'🚀', });
        console.log(data);
     
      } )
      .catch(e => {
        console.log(e);
      });
      }
      else
      {
        // dispatch(actions.create(formData, onSuccess ));
       //Função Add
      dispatch(createSlider(formData))
       .unwrap()
       .then(data => {      
        console.log(data.statusCod) 
        console.log(data.Mensagem)
        if(data.statusCod==="200"){
            // =====Show Message ==================================================================================================================
                     //"foguete","successAzul","successAzulAnim","successVerd","successAzulClaro","deleteC","deleteAnimation"
                     //"deleteOk","errorGif","excluir","confete","deleteC","erroEco","question"
                     //"bodySuccess", "bodySuccess2", "bodySuccess3","bodySuccess4","bodyconfete","bodyQuestion","bodyDelete","bodyDeleteOk","bodyErroFatal"

                     dispatch({
                      type: "SHOW_MESSAGE",   
                      msn:JSON.stringify(data.Mensagem), 
                      open:true,
                      classeParam:"confete" ,  
                      temaAlert:"bodyconfete"                   
                     });
                     setTimeout(()=>{ dispatch({type: "HIDE_MESSAGE"}); },500)
    // =====Show Message ==================================================================================================================  
            resetForm();
            dispatch(getSliders());
            setSubmitted(true);
            toast( data, {position: toast.POSITION.BOTTOM_RIGHT, style:{backgroundColor:'#6c63fe', color:'#fff'},  icon:'🚀', });
        }else{
          dispatch({
            type: "SHOW_MESSAGE",   
            msn:JSON.stringify(data.Mensagem), 
            open:true,
            classeParam:"erroEco" ,  
            temaAlert:"bodyErroFatal"                   
           });
           setTimeout(()=>{ dispatch({type: "HIDE_MESSAGE"}); },500)
        }
        
         
         // console.log(data);
       })
       .catch(e => {
         console.log(e);
         });
        }      
  };
   
  const newSlider = () => { 
    setSubmitted(false);
  };
  const closeSlider = () => { 
    setSubmitted('fechado');
 
  };
 
const CrudSchema = Yup.object().shape({
 //custom validações aqui
  
});

const formik = useFormik({
  initialValues: {
    titulo: "",
    subTitulo: "",
    descricao: "",
    subDescricao: "", 
    imageNome: "",
    imagem:"", 
  },
validationSchema: CrudSchema,    onSubmit,});   
const {errors, touched, values, handleSubmit, isSubmitting, getFieldProps, resetForm, setValues, setErrors, setFieldValue 
} = formik;
  return (    
    <Stack padding={3}>
          <AlertDialogMUI />
      { (submitted=== false) ?(
  
        <Stack spacing={1}>
           <Box sx={{ flexGrow: 1 }} >
           {/* <Fab style={{fontSize: 15 }} onClick={closeSlider} className="iconButton"  size="small" component="span"  >x</Fab> */}
           <IconButton className="iconButton"  aria-label="add to favorites" onClick={closeSlider}><ClearIcon /></IconButton> 
  
           </Box>

 <FormikProvider value={formik}>
   <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
   <Stack spacing={1}>

         <TextField fullWidth label="Titulo" {...getFieldProps('titulo')}   />
         <TextField fullWidth label="SubTitulo"   {...getFieldProps('subTitulo')}  />
         <TextField fullWidth  label="Descricao" {...getFieldProps('descricao')}  />
         <TextField fullWidth autoComplete="SubDescricao" label="SubDescricao"  {...getFieldProps('subDescricao')}  />
       
         

         <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
             <label> Adicionar Imagem:</label>
             <label> 
               <Input style={{ display: 'none' }}type="file" accept="image/*"  onChange={onChangeImg}   />
               <Fab style={{fontSize: 30 }} color="secondary" size="small" component="span" aria-label="add" variant="extended">+</Fab>
            </label>          
            {(images) ?(<Avatar sx={{ width: 70, height: 70 }}  src={images} alt=""   />): 
            ((values.imageNome) ? (<Avatar sx={{ width: 70, height: 70 }} src={values.imageNome}    alt="" /> ) :(""))}            
          </Stack>
          
          <LoadingButton fullWidth size="large" type="submit" variant="contained" > Salvar </LoadingButton>
          <LoadingButton fullWidth size="large" color='warning' variant="contained" loading={isSubmitting}onClick={() => { resetForm() }}>Limpar</LoadingButton>
          </Stack>
      </Form>
    </FormikProvider>
         
    </Stack>
   
      ): (
        <div> 
          {(submitted === 'inicio' || submitted ==='fechado') ?

          ( <Typography variant="h6" letterSpacing={2} >Cadastrar Slider</Typography>):
          ( <Typography variant="h6" letterSpacing={2} > Slider salvo com Sucesso</Typography>)}
          <Button fullWidth size="large" type="submit" variant="contained"   onClick={newSlider} > NOVO </Button>
          
        </div>
      )
      }
    </Stack>
    
  );

};

 