import PropTypes from 'prop-types';
// material
import { Avatar, Box, Stack } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
    return <Box sx={{ flexGrow: 2 }}><Stack direction={{  sm: 'row' }}   justifyContent="center" alignItems="center" >
     {/* <Avatar sx={{ width: 100, height: 100 }} style={{marginBottom:'-30px'}} src={'/static/logos/logoIEAD2_PNG.png'} alt="" /> */}
     <img style={{marginBottom:'-10px', height:'80px', width:'auto'}} src={'/static/logos/logo.png'} alt=""/>
    
    
    </Stack> 
    </Box> 
}
