
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/axios";
const initialState = [];


const getAll = () => {
  return api.get("/tabelagenerica/TbGenericaList");
};
const create = data => {
 return api.post('/tabelagenerica/novaTabela/', data);
};
const update = (id, data) => {
  return api.put(`/TabelaGenerica/${id}`, data);
};

const remove = id => {
  return api.delete(`/TabelaGenerica/${id}`);
};
const removeAll = () => {
  return api.delete(`/TabelaGenerica`);
};

const findByTitle = title => {
  return api.get(`/TabelaGenerica?title=${title}`);
};

// daqui

export const createTbGenerica = createAsyncThunk(
  "TabelaGenerica/novaTabela",
  async (formData) => {
    console.log(formData.get('TabelaDesc'));
    const res = await create(formData);
    
    return res.data;
  }
  
);
export const getTbGenerica = createAsyncThunk(
  "TbGenerica/TbGenericaList",
  async () => {
    const res = await getAll();
    return res.data;
  }
);

export const updateTbGenerica = createAsyncThunk(
  "TabelaGenerica/update",
  async ({ id, data }) => {
      // console.log(data.get('ImagemUpload'));
    const res = await update(id, data);
    return res.data;
  }
);

export const deleteTbGenerica = createAsyncThunk(
  "TabelaGenerica/delete",
  async ({ id }) => {
    const resposta = await remove(id);
    return resposta.data;
  }
);

export const deleteAllTbGenerica = createAsyncThunk(
  "TabelaGenerica/deleteAll",
  async () => {
    const res = await removeAll();
    return res.data;
  }
);

export const findTbGenericaByTitle = createAsyncThunk(
  "TabelaGenerica/findByTitle",
  async ({ title }) => {
    const res = await findByTitle(title);
    return res.data;
  }
);

const tbGenericaSlice = createSlice({
  name: "TbGenerica",
  initialState,
  extraReducers: {
 
    [createTbGenerica.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
   
    [getTbGenerica.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    
    [updateTbGenerica.fulfilled]: (state, action) => {
      const index = state.findIndex(TbGenerica => TbGenerica.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deleteTbGenerica.fulfilled]: (state, action) => {
      let index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [deleteAllTbGenerica.fulfilled]: (state, action) => {
      return [];
    },
    [findTbGenericaByTitle.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = tbGenericaSlice;
export default reducer;