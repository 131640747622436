import React, { useEffect, useState } from "react";
import { alpha, styled } from '@mui/material/styles';
import { Card, Box,  Grid,  Typography, Stack  } from '@mui/material';
import Page from '../components/Page';
 
 
import './page.css'
 
import Scroll from "../components/utilidades/ScrollToTop/Scroll"; 
import '../components/slider/fontArt.css'
import RadioPlay from "../components/audioPlay/RadioPlay";
export default function Radio() {
 
  // ----------------------------------------------------------------------
  const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
     
    }
}));

  

  return (
    <RootStyle   title="Home | Portal Trilha do Código">
    
      
         <Grid  container height={'80vh'} >       
           
          <Grid  container  >
          <Box sx={{ flexGrow: 2 }}>
             <Stack direction={{  sm: 'column' }}   justifyContent="center" alignItems="center"   > 
               <RadioPlay param='normal' />
              </Stack> 
            </Box> 
           
          </Grid> 
          
          </Grid>
          
           
         
          
         
    
 
    </RootStyle>
  );
}
