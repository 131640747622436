import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/axios";
const initialState = [];


const getAll = () => {
  return api.get("/users/UsersList");
};
const create = data => {
 return api.post('/users/novaConta/', data);
};
const update = (id, data) => {
  return api.put(`/users/${id}`, data);
};

const remove = id => {
  return api.delete(`/users/${id}`);
};
const removeAll = () => {
  return api.delete(`/users`);
};

const findById = id => {
  return api.get(`/users?id=${id}`);
};

// daqui

export const createUser = createAsyncThunk(
  "users/novaConta",
  async (formData) => {
    // console.log(formData.get('Titulo'));
    const res = await create(formData);
    
    return res.data;
  }
);
export const getUsers = createAsyncThunk(
  "users/UsersList",
  async () => {
    const res = await getAll();
    return res.data;
  }
);

export const updateUser = createAsyncThunk(
  "users/update",
  async ({ id, data }) => {
    // console.log('Imagem Reducer Slice ==>  '+formData.get("Imagem"))
    // console.log('imagemUpload Reducer Slice ==>  '+formData.get("imagemUpload"))
      // console.log(data.get('ImagemUpload'));
    const res = await update(id, data);
    console.log(data.get('Imagem'));
    return res.data;
  }
);

export const deleteUser = createAsyncThunk(
  "users/delete",
  async ({ id }) => {
    const resposta = await remove(id);
    return resposta.data;
  }
);

export const deleteAllUsers = createAsyncThunk(
  "users/deleteAll",
  async () => {
    const res = await removeAll();
    return res.data;
  }
);

export const findUsersById = createAsyncThunk(
  "users/findById",
  async ({ id }) => {
    const res = await findById(id);
    return res.data;
  }
);

const userslice = createSlice({
  name: "Users",
  initialState,
  extraReducers: {
 
    [createUser.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
   
    [getUsers.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    
    [updateUser.fulfilled]: (state, action) => {
      const index = state.findIndex(User => User.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deleteUser.fulfilled]: (state, action) => {
      let index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [deleteAllUsers.fulfilled]: (state, action) => {
      return [];
    },
    [findUsersById.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = userslice;
export default reducer;