import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from  '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Avatar, Typography } from '@mui/material';
// components
import { MHidden } from '../components/@material-extend';
import { Link as RouterLink, Outlet } from 'react-router-dom';
import Searchbar from './dashboard/Searchbar';
import AccountPopover from './dashboard/AccountPopover';
import LanguagePopover from './dashboard/LanguagePopover';
import NotificationsPopover from './dashboard/NotificationsPopover';
import MenuPages from './dashboard/MenuPages';
import { useSelector } from 'react-redux';
import Logo from '../components/Logo';
import LogoOnlyLayout from './LogoOnlyLayout';
import MenuAreaRestrita from './dashboard/MenuAreaRestrita';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0; //280; aqui define o largura qd tem barra de menus ao lado - da o desconto de 280 px
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.001),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));


 
// ----------------------------------------------------------------------
 
NavbarHomepage.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function NavbarHomepage({ onOpenSidebar }) {
  const userLogado = useSelector((state) => state.auth);
//  console.log(userLogado)
  return (
    <RootStyle>
      <ToolbarStyle>
    
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        
 
        
        <Box sx={{ flexGrow: 2 }}>
        
        <Stack direction={{  sm: 'row' }}   justifyContent="left" alignItems="center" minWidth={200}>
        <RouterLink to="/home">
          <Logo />
        </RouterLink>  
        </Stack>
  
       
        </Box>
        <Searchbar />
        
        <MenuPages/>
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
        
          <LanguagePopover />
          {/* <NotificationsPopover /> */}
          {(userLogado.name)? <AccountPopover />:
          <><Button href='/users/login' >Entrar</Button><Button style={{background:"#fca311"}} href='/users/register' >Cadastrar</Button></> }
          {(userLogado.funcao=="Admin")? <MenuAreaRestrita />:"" }
          {/* <MenuAreaRestrita /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
