import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/axios";
const initialState = [];


const getAll = () => {
  return api.get("/produtos/produtosList");
};
const create = data => {
 return api.post('/produtos/novoProduto/', data);
};
const update = (id, data) => {
  return api.put(`/produtos/${id}`, data);
};

const remove = id => {
  return api.delete(`/produtos/${id}`);
};
const removeAll = () => {
  return api.delete(`/produtos`);
};

const findByTitle = title => {
  return api.get(`/produtos?title=${title}`);
};

// daqui

export const createProduto = createAsyncThunk(
  "produtos/novoProduto",
  async (formData) => {
    // console.log(formData.get('Titulo'));
    const res = await create(formData);
    
    return res.data;
  }
);
export const getProdutos = createAsyncThunk(
  "produtos/produtosList",
  async () => {
    const res = await getAll();
    return res.data;
  }
);

export const updateProduto = createAsyncThunk(
  "produtos/update",
  async ({ id, data }) => {
      // console.log(data.get('ImagemUpload'));
    const res = await update(id, data);
    return res.data;
  }
);

export const deleteProduto = createAsyncThunk(
  "produtos/delete",
  async ({ id }) => {
    const resposta = await remove(id);
    return resposta.data;
  }
); 

export const deleteAllProdutos = createAsyncThunk(
  "produtos/deleteAll",
  async () => {
    const res = await removeAll();
    return res.data;
  }
);

export const findprodutosByTitle = createAsyncThunk(
  "produtos/findByTitle",
  async ({ title }) => {
    const res = await findByTitle(title);
    return res.data;
  }
);

const produtoslice = createSlice({
  name: "Produto",
  initialState,
  extraReducers: {
 
    [createProduto.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
   
    [getProdutos.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    
    [updateProduto.fulfilled]: (state, action) => {
      const index = state.findIndex(Produto => Produto.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deleteProduto.fulfilled]: (state, action) => {
      let index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [deleteAllProdutos.fulfilled]: (state, action) => {
      return [];
    },
    [findprodutosByTitle.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = produtoslice;
export default reducer;