
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState,   useCallback } from "react"; 
import {Box, Card, Grid, Typography, Avatar, CardMedia, autocompleteClasses,FormControl, InputLabel, MenuItem, Select,
  Paper,  Container, Table, TableHead, TableContainer,TableRow, TableCell, TableBody, ButtonGroup, Button, InputAdornment, Toolbar, Stack } from '@mui/material'; 
import { styled } from "@mui/material";
 
import DeleteOutlineIcon  from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import Page from '../components/Page';
import '../components/utilidades/StylePage.css';
import TbGenericaAdminForm from '../components/tbGenerica/TbGenericaForm' 
 
import { getTbGenerica, updateTbGenerica, deleteTbGenerica } from "../store/reducers/tbGenerica/tbGenericasSlice";
import { toast } from 'react-toastify';
 

// import {getTbGenericaNew} from "../store/reducers/TbGenerica/getTbGenericaSlice";

import {DataGrid /*,  GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarFilterButton, GridToolbarDensitySelector */ } from '@mui/x-data-grid';
 
 
const CardForm = styled('div')({ 
  display: 'flex',   
  marginTop:'-40px', 
  backgroundColor:"transparent",
  width:'100%'
});
const Card_ = styled(Card)({
  padding:"15px",
  backgroundColor:"#fff",
  marginLeft: "30px",
  transition: "0.3s",
  boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  "&:hover": {
    boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
  }
});

const RootStyle = styled(Page)(() => ({
  minHeight:'150vh',   
  alignItems: 'center', 
  
  // backgroundImage: "-webkit-linear-gradient(0deg, rgba(203, 228, 255,1) 50%, rgba(21, 37, 53,1) 50%)",
   
}));
const StyleTriangulo = styled(Card)(() => ({
  minHeight:'160vh',
  display: 'flex',    
  marginTop:'-120px',
  alignItems: 'center',
  padding: '100px 20px 10px 20px',
  
  // backgroundImage: "-webkit-linear-gradient(0deg, rgba(203, 228, 255,1) 50%, rgba(21, 37, 53,1) 50%)",
   
}));




 
export default function TbGenericaAdm() {
  const [currentWidth, setCurrentWidth]= useState(0);
  const [currentId, setCurrentId] = useState(0); 
  const [autocompleteValue, setAutocompleteValue] = useState();
 
  const TbGenericaLista = useSelector((state) => state.tbGenericaStore);
  const dispatch = useDispatch(); 
 
   const [records, setRecords] =[TbGenericaLista]; 
  const [loadingg , setLoadingg]=useState(false);
 
  
 const initFetch = useCallback(() => {
    dispatch(getTbGenerica()); 
    setCurrentWidth(100);   
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])
 
 //const headers =Object.keys(records).map(_=>_)
 
   const colunas =  
              [ 
                { field: 'id', headerName: 'Id' , width:30},
                { field: 'tabelaDesc', headerName: 'Tabela - Descrição' , width:150},
                { field: 'campo_adic_01', headerName: 'Campo_adic_01', flex: 1 },
                { field: 'campo_adic_02', headerName: 'Campo_adic_01' ,flex: 1  },
                { field: 'campo_adic_03', headerName: 'Campo_adic_01' , flex: 1 },
                { field: 'campo_adic_04', headerName: 'Campo_adic_01' , flex: 1 },
                { field: 'campo_adic_05', headerName: 'Campo_adic_01', flex: 1 },
                { field: 'campo_adic_06', headerName: 'Campo_adic_01', flex: 1 },
                // Ações
                { field: 'id_', headerName: 'AÇÃO' ,   width:120, renderCell: (cellValues) => {
                  return (
                    <ButtonGroup variant="text">
                
                    <Button><EditIcon color="primary"  onClick={() => {setCurrentId(cellValues.value)}} /></Button>
                    <Button><DeleteOutlineIcon sx={{ color: 'palevioletred' }} onClick={() => removeTbGenerica(cellValues.value)} /></Button>                     
                    </ButtonGroup>                       
                  );
                }   },
              ]          
        ; 
const removeTbGenerica = Id => {
      if (window.confirm('Voce tem certeza que quer deletar?'))     
          dispatch(deleteTbGenerica({ id: Id }))
          .unwrap()
          .then(data => {
             
            toast.error(data, {style:{backgroundColor:'#f66b92', color:'#fff'},  icon:'🚀', })
            // console.log(data);
            dispatch(getTbGenerica());            
          })
          .catch(e => {
            console.log(e);
          });
        } 


  var leg = records.length;
  const maxheigthGrid = (leg>=9)?(350):((leg<=2)?(350):((leg*80)));
   const PaperCard = styled(Card)(({ theme }) => ({
     ...theme.typography.body2,
     padding: theme.spacing(0.1),
     fontSize:'8px',
     marginLeft:'10px',
     lineHeight:0.1,
     //maxheight: 800, 
     height: maxheigthGrid,
     width: '97%',
     background: 'rgba(255, 255, 255, 0.8)', 
     color:'#fff'     
   })); 

  // console.log(currentId)
return(
  
<RootStyle>
<StyleTriangulo className={'backLinearGradiBordo'}  >

<Grid container  spacing={3} style={{position:'absolute', zIndex:'2'}} > 
 
<Grid item xs={12} >
 <Stack  height={280} direction={{ xs: 'column', sm: 'row' }}      alignContent='center' >  
          <img style={{marginLeft:'25vw', marginTop:'-30px'}}  height={280} width="auto" src="/static/img/img12.png" alt="admVector"/>&nbsp;
          <div style={{fontSize:'40px', fontWeight:'900'}}   color="rgba(51, 52, 54,1)"> <br/>  Tabelas Genericas</div>  
      </Stack>
      
 </Grid>
 <CardForm>
    <Grid item  xs={4}  >
      <Card_  className='imgBoxShadow' >      
     
       <TbGenericaAdminForm {...({ currentId, setCurrentId })}/>
       
      </Card_>
    </Grid>
    <Grid item  xs={7}   >
  

{/* Lista de Dados  style={{color:'#fff'}}*/}

     <PaperCard  >  
      {loadingg&&<p>Processando...por favor aguardar...</p>}
         <DataGrid   
          rows={records.map((item, index) => ({
            id:index,   
            id_:item.id_,  
            tabelaDesc: item.tabelaDesc,
            campo_adic_01: item.campo_adic_01,     
            campo_adic_02: item.campo_adic_02,
            campo_adic_03: item.campo_adic_03,
            campo_adic_04: item.campo_adic_04,
            campo_adic_05: item.campo_adic_05
          }))}
         
           columns={colunas} loading={loadingg } hideFooter/> 
 
       </PaperCard>

       {/* <Toast/> */}


     
    </Grid>   
   </CardForm>
</Grid>
    
<div className={'backLinearGradi backTrianguleGradi'} ></div>
</StyleTriangulo>
 
 </RootStyle>
 
);
  
 
}