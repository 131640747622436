import { AsyncStorage} from 'react'
// { NavigationActions } from 'react-navigation'

export async function getUser() {
  try {
    //console.log(localStorage.getItem("token"));
    return localStorage.getItem("token");// await AsyncStorage.getItem('token');
   
  } catch (e) {
    throw e;
  }
}

export async function storeUser(token) {
  try {
    return localStorage.setItem("token2",JSON.stringify(token)); //await AsyncStorage.removeItem('@ListApp:userToken');
  //  return await AsyncStorage.setItem('@ListApp:userToken', JSON.stringify(userToken));
  } catch (e) {
    throw e;
  }
}

export async function deleteUser() {
  try {
    return localStorage.removeItem("token") //await AsyncStorage.removeItem('@ListApp:userToken');
  } catch (e) {
    throw e;
  }
}

// NavigationService

// let navigator;

// export function setTopLevelNavigator(navigatorRef) {
//   navigator = navigatorRef;
// }

// export function navigate(routeName, params) {
//   navigator.dispatch(
//     NavigationActions.navigate({
//       routeName,
//       params,
//     }),
//   );
// }