import { Typography } from "@mui/material";
import { useEffect, useState } from "react";



export default function BodyAlertMUI(props) {
    const {propsBody,propsBodyTema}= props;
    
const bodySuccess =[( 
    <div ><div style={{height:"40vh"}}> </div>
   <Typography  display="block"  align="center"  color="#fff" fontSize='14px'  fontWeight='900' letterSpacing={3} >       
      Registro Efetivado com Sucesso!...
   </Typography>  
   <Typography  display="block"  align="center"  color="#fff" fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
    
 
    </div>
  )];
  
  const bodySuccess2 =[( 
    <div > <div style={{height:"40vh"}}> </div>
   <Typography  display="block"  align="center"  color="#000" fontSize='14px'  fontWeight='900' letterSpacing={3} >       
      Registro Efetivado com Sucesso!...
   </Typography>   
   <Typography  display="block"  align="center"  color="#000" fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
    
    </div>
  )];
   
  const bodySuccess3 =[( 
    <div ><div style={{height:"40vh"}}><img alt="register" width={"100%"} height={"auto"} src="/static/animation/confete02.gif" /></div>
   <Typography  display="block"  align="center"  color="#000" fontSize='14px'  fontWeight='900' letterSpacing={3} >       
      Registro Efetivado com Sucesso!...
   </Typography> 
   <Typography  display="block"  align="center"  color="#000" fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
    
    </div>
  )];
  // para alert escuro com confete
  const bodySuccess4 =[( 
    <div ><div style={{height:"40vh"}}><img alt="register" width={"100%"} height={"auto"} src="/static/animation/confete02.gif" /></div>
   <Typography  display="block"  align="center"  color="#fff" fontSize='14px'  fontWeight='900' letterSpacing={3} >       
      Registro Efetivado com Sucesso!...
   </Typography>   
   <Typography  display="block"  align="center"  color="#fff" fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
    
    </div>
  )];
  
  const bodyconfete =[( 
    <div ><div style={{height:"30vh", textAlign:"center",marginTop: "5vh"  }}><img alt="register" width={"150px"} height={"auto"} src="/static/animation/Ok.png" /></div>
   <Typography  display="block"  align="center"  color="#000" fontSize='25px'  fontWeight='900' letterSpacing={3} >       
      Registro Efetivado com Sucesso!...
   </Typography>   
   <Typography  display="block"  align="center"  color="#000" fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
    
    </div>
  )];
  const bodyconfeteSucess =[( 
    <div ><div style={{height:"30vh", textAlign:"center",marginTop: "3vh"  }}><img alt="register" width={"150px"} height={"auto"} src="/static/animation/Ok.png" /></div>
   <Typography  display="block"  align="center"  color="#000" fontSize='30px'  fontWeight='900' letterSpacing={3} >       
       Sucesso!...
   </Typography>   
   <Typography  display="block"  align="center"  color="#000" fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
    
    </div>
  )];
  
  const bodyQuestion =[( 
    <div ><div style={{height:"40vh"}}></div>
   <Typography  display="block"  align="center"  color="#000" fontSize='14px'  fontWeight='900' letterSpacing={3} >       
      Tem certeza que quer Submeter?...
   </Typography>   
   <Typography  display="block"  align="center"  color="#000" fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
    
    </div>
  )];
  const bodyDelete =[( 
    <div > <div style={{height:"40vh"}}></div>
   <Typography  display="block"  align="center"  color="rgba(41, 46, 49,05)" fontSize='12px'  fontWeight='900' letterSpacing={3} >       
      Tem certeza que quer Deletar?...
   </Typography>   
    
   <Typography  display="block"  align="center"  color="rgba(41, 46, 49,05)"fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
    
    </div>
  )];

  
  const bodyDeleteOk =[( 
    <div >
      <div style={{height:"40vh"}}></div>
   <Typography  display="block"  align="center"  color="rgba(41, 46, 49,05)" fontSize='12px'  fontWeight='900' letterSpacing={3} >       
      Deletado com Sucesso...
   </Typography>   
   <Typography  display="block"  align="center"  color="rgba(41, 46, 49,05)" fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
    
    </div>
  )];

  const bodyErroFatal =[( 
    <div >
      <div style={{height:'25vh'}}></div>
            <Typography  display="block"  align="center"  color="#000" fontSize='25px'  fontWeight='900' letterSpacing={3} >       
                Erro Fatal!!...
            </Typography>  
            <Typography  display="block"  align="center"  color="#000" fontSize='14px' letterSpacing={3} >{propsBody}</Typography>  
      
      </div>
  )];
   
  const [textBody, setTextBody] =useState("");
  useEffect(() => {
    funcText(propsBodyTema);    
    
  }, []); 

//"bodySuccess", "bodySuccess2", "bodySuccess3","bodySuccess4","bodyconfete","bodyQuestion","bodyDelete","bodyDeleteOk","bodyErroFatal"
  const funcText = (param) => {    
    if (param=="bodySuccess") {  setTextBody(<>{bodySuccess}</>);}
    if (param=="bodySuccess2") {  setTextBody(<>{bodySuccess2}</>);}
    if (param=="bodySuccess3") {  setTextBody(<>{bodySuccess3}</>);}
    if (param=="bodySuccess4") {  setTextBody(<>{bodySuccess4}</>);}    
    if (param=="bodyconfete") {  setTextBody(<>{bodyconfete}</>);}
    if (param=="bodyconfeteSuscess") {  setTextBody(<>{bodyconfeteSucess}</>);}    
    if (param=="bodyQuestion") {  setTextBody(<>{bodyQuestion}</>);}
    if (param=="bodyDelete") {  setTextBody(<>{bodyDelete}</>);}
    if (param=="bodyDeleteOk") {  setTextBody(<>{bodyDeleteOk}</>);}
    if (param=="bodyErroFatal") {  setTextBody(<>{bodyErroFatal}</>);}
  }
  //console.log(textBody )
  return(<>
    {propsBodyTema?<>{textBody }</>:""}
    </>

  )
};